import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
// import { capitalize } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TicketTemplateTableRow from "./ticketTemplateTableRow";
import TicketsService from "../api/tickets";
import { setTicketTemplatesData } from "../store/ticketTemplatesSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// import HomePagesMenu from '../common/HomePagesMenu';
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import SearchInput from "../common/searchInput";
import StyledTypography from "../common/StyledTypography";
import PropTypes from "prop-types";
import { getComparator, stableSort } from "../utils/systemInfo";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.custom.backgroundColor2,
    color: theme.palette.custom.text,
    padding: "0px",
    borderRadius: "16px",
    border: `1px solid ${theme.palette.custom.borderColor}`,
  },
  "& .MuiList-root": {
    padding: "0px",
  },
}));

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
    sort: true,
  },
];
function TemplatesMenu(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organziation = useSelector(({ organziation }) => organziation);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("timestamp");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const templates = useSelector(({ templates }) => templates);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [templatesInputData, setTemplatesInputData] = useState(undefined);
  const [loadingValue, setLoadingValue] = useState(true);
  const [templatesLength, setTemplatesLength] = useState(0);

  useEffect(() => {
    if (organziation) {
      let department = props.department === 0 ? "" : props.department;
      const apiKey = organziation?.api_key_list?.[0]?.key;
      setTemplatesInputData(null);
      dispatch(setTicketTemplatesData(null));
      TicketsService.GetTicketTemplateHeaders(
        "",
        department,
        "active",
        apiKey
      ).then((json) => {
        dispatch(setTicketTemplatesData({ json: json, newSearchText: "" }));
        setTemplatesInputData({ json: json, newSearchText: "" });
        setTemplatesLength(json.ticket_template_header.length);
      });
      setLoadingValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseTemplatesMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenTemplatesMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    let templatesInputObj = templatesInputData;
    templatesInputObj.newSearchText = newSearchText;
    dispatch(setTicketTemplatesData(templatesInputObj));
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (templates) {
        return stableSort(templates, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, templates]
    //[page, rowsPerPage, safety],
  );
  // const productivityLabels = [t("Work Time"), t("Idle Time"), t("Offline Time")];
  return (
    <>
      <Tooltip title="Open settings">
        <Button variant="text" onClick={handleOpenTemplatesMenu}>
          <Typography
            sx={{
              fontSize: "24px",
              mb: 0,
              color: theme.palette.custom.text,
              paddingRight: "5px",
            }}
            gutterBottom
          >
            {t("Click to select a template")}
          </Typography>
          <Typography
            sx={{ mb: 0, color: theme.palette.custom.text }}
            gutterBottom
          >
            <ArrowDropDownIcon fontSize="medium" />
          </Typography>
        </Button>
      </Tooltip>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseTemplatesMenu}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "flex",
              width: "100%",
              height: "100%",
              backgroundColor: "#2b2f38",
              padding: "11px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "21px",
              mb: 0,
              color: theme.palette.custom.dark,
              fontWeight: "bolder",
            }}
            variant="h2"
            gutterBottom
          >
            {t("NOTIFICATIONS")}:
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "11px",
            backgroundColor: theme.palette.custom.backgroundColor,
          }}
        >
          <SearchInput searchText={searchText} handleSearch={handleSearch} />
          <Divider
            sx={{ my: "10px", backgroundColor: theme.palette.custom.light }}
          />
          {loadingValue ? (
            <Box sx={{ height: "30vh", pt: "10%" }}>
              <Loading />
            </Box>
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  hideCheck={true}
                />

                <TableBody>
                  {templates && templatesLength > 0 && visibleRows.length ? (
                    visibleRows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TicketTemplateTableRow
                          setSelected={props.setSelected}
                          key={index}
                          row={row}
                          labelId={labelId}
                        />
                      );
                    })
                  ) : (
                    <StyledTypography>{t("No entries found")}</StyledTypography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <StyledTablePagination
            SelectProps={{
              inputProps: {
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: theme.palette.secondary.dark,
                      color: theme.palette.custom.text,
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: theme.palette.custom.borderColor,
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "blue",
                      },
                      "& .MuiMenuItem-root.Mui-selected:hover": {
                        backgroundColor: "blue",
                      },
                    },
                  },
                },
              },
            }}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
            component="div"
            count={templates ? templatesLength : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </StyledMenu>
    </>
  );
}
TemplatesMenu.propTypes = {
  setSelected: PropTypes.func,
  department: PropTypes.number,
};

export default TemplatesMenu;
