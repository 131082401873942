import { FormControl, InputLabel, Select } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

export default function GroupSelect(props) {
  const { label, onChange, attributes, rows } = props;
  const groups = createSelectGroups();
  const theme = useTheme();

  function createSelectGroups() {
    const result = [];

    for (const attr of attributes) {
      const values = [...new Set(rows.map((obj) => obj[attr.id]))];
      result.push({ ...attr, values });
    }
    return result;
  }

  const handleOnChange = (event) => {
    if (event === null) {
      event = [];
    }
    if (onChange) {
      const selectedValue = event.target.value;
      const selectedOption = groups.find((group) =>
        group.values?.includes(selectedValue)
      );
      onChange(event.target.value, selectedOption?.id);
    }
  };
  return (
    <FormControl
      sx={{ m: 1, minWidth: 120 }}
      variant="filled"
      style={{
        border: "1px solid",
        borderColor: theme.palette.custom.borderColor,
        borderRadius: "11px",
        backgroundColor: theme.palette.custom.backgroundColor,
      }}
    >
      <InputLabel htmlFor="grouped-native-select">{label}</InputLabel>
      <Select
        disableUnderline={true}
        native
        defaultValue=""
        id="grouped-native-select"
        label="Grouping"
        onChange={handleOnChange}
      >
        <option aria-label="None" value="" />
        {groups?.map((group) => (
          <optgroup key={group.name} label={group.name}>
            {group.values?.map((item) => (
              <option key={item} id={group.name} value={item}>
                {item}
              </option>
            ))}
          </optgroup>
        ))}
      </Select>
    </FormControl>
  );
}
GroupSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  attributes: PropTypes.array,
  rows: PropTypes.array,
};
