import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import PropTypes from 'prop-types';
import React from 'react';
ConfigButton.propTypes = {
    onClick: PropTypes.func.isRequired,
  };

function ConfigButton({onClick}) {
    const theme = useTheme();
    return (
        <Box
            onClick={onClick}
            onMouseDown={onClick}
            sx={{
                "marginLeft": "5px",
                "backgroundColor": theme.palette.custom.buttonColor,
                "borderRadius": "4px",
                "border": `1px solid ${theme.palette.custom.borderColor}`,
                "width": "46px",
                "height": "36px",
                "padding": "5px 10px",
                "&:hover": {
                    backgroundColor: theme.palette.custom.buttonHoverColor,
                    color: theme.palette.custom.buttonHoverTextColor,
                    boxShadow: "none"
                }
            }}>
            <SettingsSuggestIcon
                onClick={onClick}
                onMouseDown={onClick}
                sx={{
                    "color": theme.palette.custom.buttonTextColor,
                    "fontSize": 24,
                    "&:hover": {
                        color: theme.palette.custom.buttonHoverTextColor,
                        boxShadow: "none"
                    }
                }}
            />
        </Box>
    );
}

export default ConfigButton;
