import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
// import { capitalize } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
// import HomePagesMenu from '../common/HomePagesMenu';
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import NotificationsTableRow from "../common/Layout/notificationsTableRow";
import OrganizationService from "../api/organization";
import SearchInput from "../common/searchInput";
import GroupSelect from "../common/groupSelect";
import StyledTypography from "../common/StyledTypography";
import { getComparator, stableSort } from "../utils/systemInfo";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
const headCells = [
  {
    id: "notification",
    numeric: false,
    disablePadding: true,
    label: "Notification",
    sort: true,
  },
  {
    id: "info",
    numeric: false,
    disablePadding: true,
    label: "Info",
    sort: true,
  },
  {
    id: "timestamp",
    numeric: false,
    disablePadding: true,
    label: "Timestamp",
    sort: true,
  },
];
function NotificationsPage() {
  const theme = useTheme();
  const { t } = useTranslation();
  const organziation = useSelector(({ organziation }) => organziation);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("timestamp");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");

  const [selected, setSelected] = useState([]);
  const [notificationsList, setNotificationsList] = useState([]);
  const [notificationsListFiltered, setNotificationsListFiltered] = useState(
    []
  );
  const [loadingValue, setLoadingValue] = useState(true);
  const [attributeFilter, setAttributeFilter] = useState({});

  useEffect(() => {
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      OrganizationService.GetOperationNotifications({
        orgUid: organziation.uid,
        apiKey: apiKey,
      }).then((json) => {
        // if (json.operation_notifications) {
        //   const unique = [
        //     ...new Set(
        //       json.operation_notifications.map((item) => item.notifcation)
        //     ),
        //   ]; // [ 'A', 'B']
        setNotificationsList(json.operation_notifications);
        setNotificationsListFiltered(json.operation_notifications);
        //   }
      });
      setLoadingValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //  }, [organziation]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, notifcation) => {
    const selectedIndex = selected.indexOf(notifcation);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, notifcation);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    let notificationsFiltered = notificationsList.filter(
      (item) =>
        item.notifcation.toLowerCase().includes(newSearchText.toLowerCase()) ||
        item.info.toLowerCase().includes(newSearchText.toLowerCase())
    );
    setNotificationsListFiltered(notificationsFiltered);
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (notificationsListFiltered && notificationsListFiltered.length > 0) {
        return stableSort(
          notificationsListFiltered.filter(
            (n) =>
              n[attributeFilter.attribute] === attributeFilter.value ||
              !attributeFilter.attribute
          ),
          getComparator(order, orderBy)
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      } else {
        return [];
      }
    },
    [
      order,
      orderBy,
      page,
      rowsPerPage,
      notificationsListFiltered,
      attributeFilter,
    ]
    //[page, rowsPerPage, safety],
  );
  // const isSelected = (info) => selected.indexOf(info) !== -1;
  // const productivityLabels = [t("Work Time"), t("Idle Time"), t("Offline Time")];
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" }, width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "45px",
                  mb: 0,
                  color: theme.palette.custom.borderColor,
                }}
                variant="h2"
                gutterBottom
              >
                {
                  // {capitalize(t('Devices'))}
                }
                {t("Notifications")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SearchInput
          searchText={searchText}
          handleSearch={handleSearch}
          autoFocus
          sx={{
            width: { xs: "100%", sm: "20%" },
            borderColor: theme.palette.custom.borderColor,
            borderRadius: "11px",
            backgroundColor: theme.palette.custom.light,
          }}
        />
        {notificationsListFiltered?.length && (
          <GroupSelect
            label={t("Select")}
            attributes={[{ id: "notifcation", name: t("Notifcation") }]}
            rows={notificationsListFiltered}
            onChange={(value, attribute) =>
              setAttributeFilter({ value, attribute })
            }
          />
        )}
      </Box>

      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              hideCheck
            />

            <TableBody>
              {notificationsListFiltered && visibleRows.length ? (
                visibleRows.map((row, index) => {
                  // const isItemSelected = isSelected(row.notification);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <NotificationsTableRow
                      key={index}
                      row={row}
                      handleClick={handleClick}
                      labelId={labelId}
                      viewAllPage={true}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={notificationsList ? notificationsList.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default NotificationsPage;
