import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TicketsService from "../api/tickets";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TicketsTableRow from "./ticketsTableRow";
import SearchInput from "../common/searchInput";
import StyledTypography from "../common/StyledTypography";
import PropTypes from "prop-types";
import { getComparator, stableSort } from "../utils/systemInfo";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
const headCells = [
  {
    id: "ticket_number",
    numeric: false,
    disablePadding: true,
    label: "TicketNumber",
    sort: true,
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    sort: true,
  },
  {
    id: "operation.full_name",
    numeric: false,
    disablePadding: false,
    label: "TicketOrganization",
    sort: true,
  },
  {
    id: `assigned_to_person.firstname`,
    numeric: false,
    disablePadding: false,
    label: "AssignedTo",
    sort: true,
  },
  {
    id: `template.name`,
    numeric: false,
    disablePadding: false,
    label: "TicketType",
    sort: true,
  },
  {
    id: `created_timestamp`,
    numeric: false,
    disablePadding: false,
    label: "Created",
    sort: true,
  },
  {
    id: `updated_timestamp`,
    numeric: false,
    disablePadding: false,
    label: "Updated",
    sort: true,
  },
];
let ticketsBackendList = [];

export default function TicketsGeneralTab({ selectedTab }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const organziation = useSelector(({ organziation }) => organziation);
  const user = useSelector(({ user }) => user);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticket_number");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");

  const [selected, setSelected] = useState([]);
  const [loadingValue, setLoadingValue] = useState(true);

  const [ticketsList, setTicketsList] = useState(undefined);
  useEffect(() => {
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      // if (organziation) {
      setTicketsList(null);
      ticketsBackendList = [];
      // const apiKey = organziation?.api_key_list?.[0]?.key;
      TicketsService.GetTickets({
        department_id:
          selectedTab.id !== 0 && selectedTab.id !== 1 ? selectedTab.id : "",
        created_by_person_uid: selectedTab.id === 0 ? user.profileUid : "",
        assigned_to_person_uid: selectedTab.id === 1 ? user.profileUid : "",
        apiKey,
      })
        .then((json) => {
          if (json && json.ticket_header && json.ticket_header.length > 0) {
            setTicketsList(json.ticket_header);
            ticketsBackendList = json.ticket_header;
          }
          setLoadingValue(false);
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    } else {
      setLoadingValue(false);
      setTicketsList(null);
    }
  }, [organziation, selectedTab, user]);
  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    if (
      newSearchText &&
      newSearchText.length > 0 &&
      ticketsList &&
      ticketsList.length > 0
    ) {
      setTicketsList(
        ticketsBackendList.filter(
          (item) =>
            item.ticket_number
              .toLowerCase()
              .includes(newSearchText.toLowerCase()) ||
            item.operation?.full_name
              .toLowerCase()
              .includes(newSearchText.toLowerCase()) ||
            `${item.assigned_to_person?.firstname} ${item.assigned_to_person?.lastname}`
              .toLowerCase()
              .includes(newSearchText.toLowerCase()) ||
            item.template?.name
              .toLowerCase()
              .includes(newSearchText.toLowerCase())
        )
      );
    }
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (ticketsList && ticketsList.length > 0) {
        return stableSort(ticketsList, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, ticketsList]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  return (
    <Box
      sx={{
        p: "12px",
        mt: "6px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          {/* <HomePagesMenu pageName={'Maintenance'} /> */}
          <Typography
            sx={{
              fontSize: "35px",
              mb: 0,
              color: theme.palette.custom.text,
            }}
            variant="h4"
            gutterBottom
          >
            {/* {capitalize(t('Devices'))} */}
            {t(selectedTab.name)}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <SearchInput searchText={searchText} handleSearch={handleSearch} />
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              hideCheck={true}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {ticketsList && ticketsList.length > 0 && visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.ticket_number);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TicketsTableRow
                      key={index}
                      row={row}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={ticketsList ? ticketsList.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
TicketsGeneralTab.propTypes = {
  selectedTab: PropTypes.object.isRequired
};
