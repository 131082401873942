/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setMachineGroupsData = createAsyncThunk("machineGroups/setMachineGroupsData", async (inputData) => {
  if (inputData === null) {
    return null;
  }
  if (
    inputData.newSearchText &&
    inputData.newSearchText.length > 0 &&
    inputData.json &&
    inputData.json.length > 0
  ) {
    const searchText = inputData.newSearchText.toLowerCase();
    return inputData.json.filter(
      (item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText)
        )
    );
  }
  return inputData.json;
}
);

const initialState = null;

const machineGroupsSlice = createSlice({
  name: "machineGroups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setMachineGroupsData.fulfilled, (state, action) => action.payload);
  },
});

export const selectMachineGroups = ({ machineGroups }) => machineGroups;

export default machineGroupsSlice.reducer;
