import React from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme, styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import PropTypes from "prop-types";
import { LatestDataColoring } from "../utils/systemInfo";
import TableGreenTypography from "../common/TableGreenTypography";
import StyledTypography from "../common/StyledTypography";

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  width: " 50px",
  height: "32px",
  margin: "0px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));


export default function DeviceGroupLinkTableRow({
  row,
  onClick,
  labelId,
  isDevice,
  showNamedLocationOriginal,
  isLinked,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const timezoneMins = useSelector(({ timezone }) => timezone);

  // const hanleViewHistoryPage = () => {
  //     const href = `/deviceHistory?Dashboard=${row?.GrafanaDashboardUID}&MachineName=${row?.DeviceName}&device_uid=${row?.DeviceID}&DeviceTitleDataName=${row?.DeviceTitleDataName}&timerange`;
  //     // const newUrl = window.location
  //      navigate(href)
  // }
  function updateImagePath(imagePath) {
    const colorTheme = localStorage.getItem('color_theme');
    if (colorTheme === 'dark') {
      return imagePath?.replace('Light', 'Dark');
    } else if (colorTheme === 'light') {
      return imagePath?.replace('Dark', 'Light');
    }
    return imagePath;
  }

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row?.name}
      sx={{ cursor: "pointer" }}
    >
      <TableCell align="left">
        <StyledTypography variant="subtitle2">
          {row?.DeviceName}
        </StyledTypography>{" "}
      </TableCell>
      <TableCell align="left" >
        <StyledTypography variant="subtitle2">
          {row?.DeviceTitleDataName === "Device"
            ? row?.DeviceSerial
            : `${row?.DeviceSerialHex} (${row?.DeviceSerial})`}
        </StyledTypography>
      </TableCell>
      <TableCell
        sx={{ height: "100%", color: theme.palette.custom.text }}
        component="th"
        id={labelId}
        scope="row"
        padding="none"
      >
        <Box
          sx={{
            textAlign: "left",
            width: "100%",
            display: "flex",
            flexFlow: "row",
            pl: "16px",
          }}
        >
          <div
            style={{ width: "40px" }}
            dangerouslySetInnerHTML={{
              __html: row?.DeviceTypeIcon?.replace(
                "theme.palette.custom.text",
                theme.palette.custom.text
              ),
            }}
          />{" "}
          <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
            {row?.DeviceType}
          </StyledTypography>{" "}
        </Box>
      </TableCell>
      <TableCell
        sx={{ height: "100%", color: theme.palette.custom.text }}
        component="th"
        id={labelId}
        scope="row"
        padding="none"
      >
        <Box
          sx={{
            textAlign: "center",
            width: "100%",
            display: "flex",
            flexFlow: "row",
            pl: "16px",
          }}
        >
          <img width="40px" src={updateImagePath(row?.DeviceOEMIcon)} alt="logo" />
          <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
            {row?.DeviceOEM}
          </StyledTypography>{" "}
        </Box>
      </TableCell>
      {!isDevice && showNamedLocationOriginal && (
        <TableCell align="left">
          {row?.NamedLocationOriginal === "Unknown" ? (
            <TableGreenTypography color="red" variant="subtitle2">
              {row?.NamedLocationOriginal}
            </TableGreenTypography>
          ) : (
            <StyledTypography variant="subtitle2">
              {row?.NamedLocationOriginal}
            </StyledTypography>
          )}
        </TableCell>
      )}

      <TableCell align="left">
        <TableGreenTypography
          color={LatestDataColoring(row?.DeviceLastSeen, true)}
          variant="subtitle2"
        >
          {row?.DeviceLastSeen !== "Not set" &&
            row?.DeviceLastSeen !== " not seen"
            ? moment
              .utc(row?.DeviceLastSeen)
              .add(timezoneMins, "minutes")
              .format("YYYY-MM-DD HH:mm")
            : row?.DeviceLastSeen}
        </TableGreenTypography>
      </TableCell>
      <TableCell align="center">
        <Box
          sx={{
            textAlign: "center",
            width: "100%",
            display: "flex",
            flexFlow: "row",
          }}
        >
          <StyledButton
            variant="contained"
            sx={{ mb: "4px" }}
            onClick={onClick}
          >
            {isLinked ? t("Unlink") : t("Link")}
          </StyledButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}
DeviceGroupLinkTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  labelId: PropTypes.string,
  showNamedLocationOriginal: PropTypes.bool,
  isDevice: PropTypes.bool,
  isLinked: PropTypes.bool,
};
