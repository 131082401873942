import React from 'react';
import Typography from "@mui/material/Typography";
import PropTypes from 'prop-types';

export default function NumberTypography({ children, color }) {
  return (
    <Typography
      sx={{
        color: color ?? "#198754",
        m: 0,
      }}
    >
      {children}
    </Typography>
  );
}
NumberTypography.propTypes = {
  children: PropTypes.element,
  color: PropTypes.string,
};
