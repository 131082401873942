import React, {useEffect, useState, useRef} from "react";
import {Box, FormControl, Select, MenuItem, TableCell, TableRow, Tooltip, Typography, styled} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import StyledTypography from "../common/StyledTypography";
import ProjectService from "../api/project";
import { useSelector } from "react-redux";
import ViewButton from "../common/viewButton.js";
import CommentButton from "../common/commentButton.js";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Off from "../common/images/robot_icons/light_off.svg";
import Green from "../common/images/robot_icons/green_light_on.svg";
import Red from "../common/images/robot_icons/red_light_on.svg";
import Yellow from "../common/images/robot_icons/yellow_light_on.svg";
import Orange from "../common/images/robot_icons/orange_light_on.svg";
import Pink from "../common/images/robot_icons/pink_light_on.svg";
import Grey from "../common/images/robot_icons/grey_light_on.svg";
import handleNavigate from "../common/customFunctions/handleNavigate.js";
import PropTypes from "prop-types";

const CustomSelect = styled(Select)(({ theme }) => ({
    "background": theme.palette.custom.inputBackgroundColor,
    "height": "36px",
    "width": "100%",
    "minWidth": "100px",
    "marginLeft": "10px",
    "color": theme.palette.custom.text,
    "outlineColor": theme.palette.custom.borderColor,
    "&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.custom.borderColor,
        color: theme.palette.custom.borderColor
    },
    "&.Mui-focused": {
        color: theme.palette.custom.text
    },
    "&:focused": {
        color: theme.palette.custom.borderColor
    },
    "& .MuiList-root": {
        backgroundColor: theme.palette.custom.inputBackgroundColor
    }
}));
export default function MachineStatusTableRow({ row, labelId, handleChangeStatusData, hidden }) {
    const organziation = useSelector(({ organziation }) => organziation);
    const apiKey = organziation?.api_key_list?.[0]?.key;
    const [openSelects, setOpenSelects] = useState({});
    const [imageSources, setImageSources] = useState({});
    const [comment, setComment] = useState(row.latest_comment);
    const navigate = useNavigate();
    const theme = useTheme();
    const imageRef = useRef(null);
    // console.log(referenceRow)
    const handleChange = (event) => {
        const { name, value } = event.target;
        handleChangeStatusData(row, value, name, row.tasks);
        updateImageSource(name, value);
        ProjectService.SetParamVal("", name, value, "active", apiKey);
    };

    const handleImageClick = (event) => {
        let taskParamId = event.target.id;
        setOpenSelects((prevState) => ({
            ...prevState,
            [taskParamId]: !prevState[taskParamId]
        }));
    };

    function CommentTableCell({comment, handleCommentClick}) {
        return (
            <TableCell align="left" onClick={handleCommentClick} sx={{padding: "8px 16px", minHeight: "56px"}}>
                <Tooltip title={comment}>
                    <Box
                        sx={{
                            minWidth: 100,
                            maxWidth: 200,
                            display: "block",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            textOverflow: "ellipsis",
                            lineHeight: "1.5em",
                            maxHeight: "3em"
                        }}>
                        <Typography variant="subtitle2" noWrap={true}>
                            {comment}
                        </Typography>
                    </Box>
                </Tooltip>
            </TableCell>
        );
    }

    CommentTableCell.propTypes = {
        comment: PropTypes.string,
        handleCommentClick: PropTypes.func,
    };
    const updateImageSource = (taskParamId, value) => {
        let newSrc = Off;
        switch (value) {
            case 0:
                newSrc = Off;
                break;
            case 1:
                newSrc = Yellow;
                break;
            case 2:
                newSrc = Orange;
                break;
            case 3:
                newSrc = Red;
                break;
            case 4:
                newSrc = Pink;
                break;
            case 5:
                newSrc = Green;
                break;
            case 6:
                newSrc = Grey;
                break;
            default:
                newSrc = Off;
        }
        setImageSources((prevState) => ({
            ...prevState,
            [taskParamId]: newSrc
        }));
    };

    useEffect(() => {
        row.tasks.forEach((task) => {
            // console.log(task)
            updateImageSource(task.task_param_id.toString(), Number(task.value));
        });
    }, [row.tasks, row.task_group_id, apiKey]);

    async function handleCommentAdd() {
        let inputValue;
        const { value: comment } = await Swal.fire({
            title: "Add Comment",
            showCancelButton: true,
            confirmButtonText: "Post",
            color: theme.palette.custom.text,
            background: theme.palette.custom.inputBackgroundColor,
            input: "text",
            inputLabel: "Add Comment",
            inputValue,
            inputValidator: (value) => {
                if (!value) {
                    return "You need to write something!";
                }
            }
        });
        if (comment) {
            await ProjectService.SetGroupComment(row.task_group_id, comment, "active", apiKey).then((json) => {
                if (json) {
                    setComment(comment);
                }
            });
        }
    }

    // const handleView = (event) => {
    //     handleNavigate(event, navigate, `/addMachineStatus?projectId=${projectID}&groupId=${row.task_group_id}`);
    //     // navigate();
    // };

    const handleCommentClick = (event) => {
        handleNavigate(event, navigate, `/machineComments?groupId=${row.task_group_id}&name=${row.name}`);
        // navigate();
    };

    const CustomSelectImage = styled(Select)(({ name }) => ({
        "background": "transparent",
        "height": "40px",
        "width": "40px",
        "color": "transparent",
        "outlineColor": "transparent",
        "backgroundImage": `url(${imageSources[name.toString()]})`,
        "backgroundRepeat": "no-repeat",
        "&root": {
            borderColor: "transparent",
            color: "transparent",
            border: "0"
        },
        "&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
            color: "transparent",
            border: "0"
        },
        "&.Mui-focused": {
            color: "transparent"
        },
        "&:focused": {
            color: "transparent"
        },
        "&:before": {
            display: "none",
            borderColor: "transparent",
            color: "transparent",
            border: "0"
        },
        "& .MuiList-root": {
            backgroundColor: "transparent"
        },
        "&:after": {
            borderColor: "transparent",
            color: "transparent",
            border: "0",
            display: "none"
        }
    }));

    const GetSelectType = (e) => {
        let selectComponent = (
            <CustomSelect
                labelId="Status"
                value={e.value === "" ? 0 : e.value}
                name={e.task_param_id}
                open={hidden ? false : !!openSelects[e.task_param_id]}
                onClose={() =>
                    setOpenSelects((prevState) => ({
                        ...prevState,
                        [e.task_param_id]: false
                    }))
                }
                onOpen={() =>
                    setOpenSelects((prevState) => ({
                        ...prevState,
                        [e.task_param_id]: true
                    }))
                }
                onChange={handleChange}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            "backgroundColor": theme.palette.custom.inputBackgroundColor,
                            "& .MuiMenuItem-root.Mui-selected": {
                                backgroundColor: theme.palette.primary.main
                            },
                            "& .MuiMenuItem-root:hover": {
                                backgroundColor: theme.palette.custom.buttonHoverColor
                            },
                            "& .MuiMenuItem-root.Mui-selected:hover": {
                                backgroundColor: theme.palette.custom.backgroundColor2
                            }
                        }
                    }
                }}>
                <MenuItem value={0}>Not Started</MenuItem>
                <MenuItem value={1}>Started</MenuItem>
                <MenuItem value={2}>Holding</MenuItem>
                <MenuItem value={3}>Problem</MenuItem>
                <MenuItem value={4}>Cancelled</MenuItem>
                <MenuItem value={5}>Completed</MenuItem>
                <MenuItem value={6}>Not Applicable</MenuItem>
            </CustomSelect>
        );
        // console.log(selectComponent)
        if (!hidden) {
            return selectComponent;
        } else {
            return (
                <CustomSelectImage
                    variant="standard"
                    labelId="Status"
                    value={e.value === "" ? 0 : e.value}
                    name={e.task_param_id}
                    IconComponent={() => null}
                    open={!!openSelects[e.task_param_id]}
                    onClose={() =>
                        setOpenSelects((prevState) => ({
                            ...prevState,
                            [e.task_param_id]: false
                        }))
                    }
                    onOpen={() =>
                        setOpenSelects((prevState) => ({
                            ...prevState,
                            [e.task_param_id]: true
                        }))
                    }
                    onChange={handleChange}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                "backgroundColor": theme.palette.custom.inputBackgroundColor,
                                "& .MuiMenuItem-root.Mui-selected": {
                                    backgroundColor: theme.palette.primary.main
                                },
                                "& .MuiMenuItem-root:hover": {
                                    backgroundColor: theme.palette.custom.buttonHoverColor
                                },
                                "& .MuiMenuItem-root.Mui-selected:hover": {
                                    backgroundColor: theme.palette.custom.backgroundColor2
                                }
                            }
                        }
                    }}>
                    <MenuItem value={0}>Not Started</MenuItem>
                    <MenuItem value={1}>Started</MenuItem>
                    <MenuItem value={2}>Holding</MenuItem>
                    <MenuItem value={3}>Problem</MenuItem>
                    <MenuItem value={4}>Cancelled</MenuItem>
                    <MenuItem value={5}>Completed</MenuItem>
                    <MenuItem value={6}>Not Applicable</MenuItem>
                </CustomSelectImage>
            );
        }
    };

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.task_group_id} sx={{ cursor: "pointer" }}>
            <TableCell sx={{ height: "100%", color: theme.palette.custom.text }} component="th" id={labelId} scope="row" padding="none">
                <Box sx={{ textAlign: "center", width: "100%", display: "flex", flexFlow: "row" }}>
                    <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
                        {row.name}
                    </StyledTypography>
                </Box>
            </TableCell>
            {row.tasks.map((e) => (
                <TableCell align="left" key={e.task_param_id}>
                    <FormControl sx={{ flexDirection: "row", alignItems: "start", justifyContent: "center" }}>
                        {!hidden && <img ref={imageRef} src={imageSources[e.task_param_id.toString()]} alt="status_icon" style={{ width: "40px", height: "40px", cursor: "pointer" }} onClick={handleImageClick} id={`${e.task_param_id}`} />}
                        {GetSelectType(e)}
                    </FormControl>
                </TableCell>
            ))}
            <CommentTableCell onClick={handleCommentClick} comment={comment}></CommentTableCell>
            <TableCell align="center">
                <Box sx={{display: "flex", flexFlow: "row", justifyContent: "center"}}>
                    <ViewButton onClick={handleNavigate} />
                    <CommentButton onClick={handleCommentAdd} />
                </Box>
            </TableCell>
        </TableRow>
    );
}
MachineStatusTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    labelId: PropTypes.string,
    handleChangeStatusData: PropTypes.func,
    projectID: PropTypes.number,
    hidden: PropTypes.bool,
    userList: PropTypes.array,
};
