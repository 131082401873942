import React from "react";
import { useTheme } from '@mui/material/styles';

function RedCircle() {
    const theme = useTheme();
    return (
        <div className="me-2" style={{
            height: "30px", width: "30px", backgroundColor: "red",
            display: "inline-block", borderRadius: "50%",
            borderColor: theme.palette.custom.borderColor, marginRight: '4px',
        }} />
    );
}

export default RedCircle;
