import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import StyledTypography from "../common/StyledTypography";
import ViewButton from "../common/viewButton";
import handleNavigate from "../common/customFunctions/handleNavigate";

export default function ShiftsTableRow({ row, labelId }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const handleClick = (event, name, orgUid) => {
        handleNavigate(event, navigate, `/addShift?orgUid=${orgUid}&action=edit&configUid=${row.shift_time_config_uid}`);
        // navigate();
    };
    return (
        <TableRow hover tabIndex={-1} key={row.name} sx={{ cursor: "pointer" }}>
            <TableCell sx={{ height: "100%", color: theme.palette.custom.text, padding: "16px" }} component="th" id={labelId} scope="row">
                <Box sx={{ textAlign: "center", width: "100%", display: "flex", flexFlow: "row", justifyContent: "left" }}>
                    <StyledTypography variant="subtitle2">{row.name}</StyledTypography>{" "}
                </Box>
            </TableCell>
            <TableCell sx={{ height: "100%", color: theme.palette.custom.text, padding: "16px" }} component="th" id={labelId} scope="row">
                <Box sx={{ textAlign: "center", width: "100%", display: "flex", flexFlow: "row", justifyContent: "left" }}>
                    <StyledTypography variant="subtitle2">{row.start_time}</StyledTypography>{" "}
                </Box>
            </TableCell>
            <TableCell sx={{ height: "100%", color: theme.palette.custom.text, padding: "16px" }} component="th" id={labelId} scope="row">
                <Box sx={{ textAlign: "center", width: "100%", display: "flex", flexFlow: "row", justifyContent: "left" }}>
                    <StyledTypography variant="subtitle2">{row.end_time}</StyledTypography>{" "}
                </Box>
            </TableCell>
            <TableCell align="left">
                {row.shift_time_config_uid !== undefined ?
                    <ViewButton onClick={(event) => handleClick(event, row.name, row.operation_uid)} />
                    :
                    <></>}
            </TableCell>
        </TableRow>
    );
}
ShiftsTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    labelId: PropTypes.string,
};
