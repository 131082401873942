import React from "react";
import {useNavigate} from "react-router-dom";
import { default as handleNavigateFunction } from "./customFunctions/handleNavigate";

function ProgressBar(statInfo) {
    const navigate = useNavigate();
    function handleNavigate(event,route) {
        handleNavigateFunction(event, navigate, `/tickets&status=${route}`);
        // navigate();
    }
    const statusInfo = statInfo.statusInfo;
    if (statusInfo.loggedTotal > 0) {
        if (statusInfo.stsNew !== "") {
            return (
                <div className="row">
                    <span
                        className="progress"
                        style={{
                            backgroundColor: "inherit",
                            height: "75px",
                            marginTop: "1.75rem",
                            display: "flex",
                            width: "100%"
                        }}>
                        <span
                            onClick={(e) => {
                                handleNavigate(e,"new");
                            }}
                            className="progress-bar"
                            role="progressbar"
                            id="leftBar"
                            style={{display: "flex", width: `${statusInfo.stsNewPerc}%`, backgroundColor: "#0000FF", justifyContent: "space-around", alignItems: "center", color: "#FFFFFF", fontWeight: "bold", fontSize: "46px"}}
                            aria-valuenow={statusInfo.stsNewPerc}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title={`New ${Math.round(statusInfo.stsNewPerc)}% | ${statusInfo.stsNew} Tickets`}>
                            {statusInfo.stsNew}
                        </span>
                        <span
                            onClick={(e) => {
                                handleNavigate(e ,"open");
                            }}
                            className="progress-bar"
                            role="progressbar"
                            style={{width: `${statusInfo.stsOpenPerc}%`, backgroundColor: "#00FF00", display: "flex", justifyContent: "space-around", alignItems: "center", color: "#FFFFFF", fontWeight: "bold", fontSize: "46px"}}
                            aria-valuenow={statusInfo.stsOpenPerc}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title={`Open ${Math.round(statusInfo.stsOpenPerc)}% | ${statusInfo.stsOpen} Tickets`}>
                            {statusInfo.stsOpen}
                        </span>
                        <span
                            onClick={(e) => {
                                handleNavigate(e,"closed");
                            }}
                            className="progress-bar"
                            role="progressbar"
                            id="rightBar"
                            style={{width: `${statusInfo.stsClosedPerc}%`, backgroundColor: "#FF0000", display: "flex", justifyContent: "space-around", alignItems: "center", color: "#FFFFFF", fontWeight: "bold", fontSize: "46px"}}
                            aria-valuenow={statusInfo.stsClosedPerc}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title={`Closed ${Math.round(statusInfo.stsClosedPerc)}% | ${statusInfo.stsClosed} Tickets`}>
                            {statusInfo.stsClosed}
                        </span>
                    </span>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div
                        className="progress"
                        style={{
                            backgroundColor: "inherit",
                            height: "75px",
                            marginTop: "1.75rem"
                        }}>
                        <span
                            onClick={(e) => {
                                handleNavigate(e,"open");
                            }}
                            className="progress-bar"
                            role="progressbar"
                            style={{width: `${statusInfo.stsOpenPerc}%`, backgroundColor: "#00FF00", display: "flex", justifyContent: "space-around", alignItems: "center", color: "#FFFFFF", fontWeight: "bold", fontSize: "46px"}}
                            aria-valuenow={statusInfo.stsOpenPerc}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title={`Open ${Math.round(statusInfo.stsOpenPerc)}% | ${statusInfo.stsOpen} Tickets`}>
                            {statusInfo.stsOpen}
                        </span>
                        <span
                            onClick={(e) => {
                                handleNavigate(e,"closed");
                            }}
                            className="progress-bar"
                            role="progressbar"
                            id="rightBar"
                            style={{width: `${statusInfo.stsClosedPerc}%`, backgroundColor: "#FF0000", display: "flex", justifyContent: "space-around", alignItems: "center", color: "#FFFFFF", fontWeight: "bold", fontSize: "46px"}}
                            aria-valuenow={statusInfo.stsClosedPerc}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title={`Closed ${Math.round(statusInfo.stsClosedPerc)}% | ${statusInfo.stsClosed} Tickets`}>
                            {statusInfo.stsClosed}
                        </span>
                    </div>
                </div>
            );
        }
    }
}

export default ProgressBar;
