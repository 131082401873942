import React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StyledTextField = styled(TextField)(({ theme }) => ({
    "root": {
        "width": { xs: "100%", sm: "20%" },
        "border": 1,
        "borderColor": theme.palette.custom.borderColor,
        "borderRadius": "11px",
        "backgroundColor": "transparent",
        "color": theme.palette.custom.text,
        "outline": "none",
        "outlineOffset": "0px",
        "textAlign": "center",
        "marginBottom": "16px",
        "textDecoration": "none",
        "&:hover": {
            border: 0
        },
        "justifyContent": "center",
        "alignItems": "center"
    },
    "& .MuiFilledInput-underline": {
        border: `1px solid ${theme.palette.custom.borderColor}`,
        "borderRadius": "11px",
        "&:before": {
            height: "100%",
            border: 0, // Remove the border
            borderRadius: "11px",
            padding: "11px",
            marginTop: "5px",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent"
        },
        "&:after": {
            border: 0, // Remove the border
            borderRadius: "11px",
            backgroundColor: "transparent"
        },
        "&:hover:before, &:hover:after": {
            border: "0 !important", // Ensure no border on hover
            backgroundColor: "transparent"
        }
    },
    "& .MuiFilledInput-root": {
        "&:after": {
            border: 0 // Remove any remaining border
        }
    },
    "& .MuiFilledInput-input": {
        "borderRadius": "11px",
    },
}));

export default function CustomTextField(props) {
    return (
        <StyledTextField {...props}
            InputLabelProps={{ style: { color: 'white', mixBlendMode: 'difference' } }}
            inputProps={{ maxLength: 32, ...props.inputProps }}
        >
            {props.children}
        </StyledTextField>
    );
}
CustomTextField.propTypes = {
    inputProps: PropTypes.object,
    children: PropTypes.element,
};