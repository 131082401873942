import * as React from 'react';
import styled from '@emotion/styled';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import NestedMenuItem from "./NestedMenuItem"

const StyledMenu = styled((props) => <Menu  {...props} />)(
    ({ theme }) => ({
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.custom.light
        }
    }),
);
export const Dropdown = React.forwardRef(
    (
        {
            trigger,
            menu,
            keepOpen: keepOpenGlobal,
            isOpen: controlledIsOpen,
            onOpen: onControlledOpen,
            minWidth,
        },
        ref
    ) => {
        const [isInternalOpen, setInternalOpen] = React.useState(null);

        const isOpen = controlledIsOpen || isInternalOpen;

        let anchorRef = React.useRef(null);
        if (ref) {
            anchorRef = ref;
        }

        const handleOpen = (event) => {
            event.stopPropagation();

            if (menu.length) {
                onControlledOpen
                    ? onControlledOpen(event.currentTarget)
                    : setInternalOpen(event.currentTarget);
            }
        };

        const handleClose = (event) => {
            event.stopPropagation();

            if (
                anchorRef.current &&
                anchorRef.current.contains(event.target)
            ) {
                return;
            }

            handleForceClose();
        };

        const handleForceClose = () => {
            onControlledOpen
                ? onControlledOpen(null)
                : setInternalOpen(null);
        };

        const renderMenu = (menuItem, index) => {
            const { keepOpen: keepOpenLocal, ...props } = menuItem.props;

            let extraProps = {};
            if (props.menu) {
                extraProps = {
                    parentMenuOpen: isOpen,
                };
            }

            return React.createElement(
                menuItem.type,
                {
                    ...props,
                    key: index,
                    ...extraProps,
                    onClick: (event) => {
                        event.stopPropagation();

                        if (!keepOpenGlobal && !keepOpenLocal) {
                            handleClose(event);
                        }

                        if (menuItem.props.onClick) {
                            menuItem.props.onClick(event);
                        }
                    },
                },
                props.menu
                    ? React.Children.map(props.menu, renderMenu)
                    : props.children,
            );
        };
        renderMenu.propTypes = {
            children: PropTypes.element,
        };

        return (
            <>
                {React.cloneElement(trigger, {
                    onClick: isOpen ? handleForceClose : handleOpen,
                    ref: anchorRef,
                })}

                <StyledMenu
                    PaperProps={{ sx: { minWidth: minWidth ?? 0 } }}
                    anchorEl={isOpen}
                    open={!!isOpen}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}

                >
                    {React.Children.map(menu, renderMenu)}
                </StyledMenu>
            </>
        );
    }
);
Dropdown.propTypes = {
    trigger: PropTypes.element,
    children: PropTypes.element,
    menu: PropTypes.array,
    keepOpen: PropTypes.bool,
    isOpen: PropTypes.array,
    onOpen: PropTypes.func,
    minWidth: PropTypes.string,
};
Dropdown.displayName = 'Dropdown';

export const DropdownMenuItem = styled(MenuItem)`
  display: flex;
  & > svg {
    margin-left: 0px;
  }
`;

export const DropdownNestedMenuItem = styled(NestedMenuItem)`
  display: flex;
  justify-content: space-between !important;
  & > svg {
    margin-left: 0px;
  }
`;