import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Make0IfNegative } from "../utils/systemInfo";
import PropTypes from "prop-types";

export default function OpsumTotalsComparisonProgress({
  ProductionJson,
  scheduled_time,
}) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const TotalProductionTime =
    ProductionJson.downtime + ProductionJson.idle + ProductionJson.tramming;
  const WorkingTime = Make0IfNegative(ProductionJson.tramming / 60);
  const IdleTime = Make0IfNegative(ProductionJson.idle / 60);
  const UnscheduledTime = Make0IfNegative(
    (TotalProductionTime - scheduled_time) / 60
  );
  const DownTime = Make0IfNegative(
    ProductionJson.downtime / 60 - UnscheduledTime
  );
  let DownTimeWithoutUnscheduled = Make0IfNegative(
    ProductionJson.downtime / 60
  );

  const WorkingTimePercent = Make0IfNegative(
    (WorkingTime / (TotalProductionTime / 60)) * 100
  );
  const IdleTimePercent = Make0IfNegative(
    (IdleTime / (TotalProductionTime / 60)) * 100
  );
  const UnscheduledTimePercent = Make0IfNegative(
    (UnscheduledTime / (TotalProductionTime / 60)) * 100
  );
  const DownTimePercent = Make0IfNegative(
    (DownTime / (TotalProductionTime / 60)) * 100
  );
  let DownTimeWithoutUnscheduledPercent = Make0IfNegative(
    (DownTimeWithoutUnscheduled / (TotalProductionTime / 60)) * 100
  );

  let WorkingTimeBorderCSS = "0px";
  let IdleTimeBorderCSS = "0px";
  let DownTimeBorderCSS = "";
  // let UnscheduledTimeBorderCSS = "";

  if (WorkingTime > 0) WorkingTimeBorderCSS = "8px 0px 0px 8px";
  if (WorkingTime === 0 && IdleTime > 0) IdleTimeBorderCSS = "8px 0px 0px 8px";
  if (WorkingTime === 0 && IdleTime === 0 && DownTime > 0)
    DownTimeBorderCSS = "8px 0px 0px 8px";

  // if (UnscheduledTime > 0) UnscheduledTimeBorderCSS = "border-radius: 0px 9px 9px 0px";
  if (UnscheduledTime === 0 && DownTime > 0)
    DownTimeBorderCSS = "0px 9px 9px 0px";
  if (UnscheduledTime === 0 && DownTime === 0 && IdleTime > 0)
    IdleTimeBorderCSS = "0px 9px 9px 0px";

  if (
    UnscheduledTime === 0 &&
    DownTime === 0 &&
    IdleTime === 0 &&
    WorkingTime > 0
  )
    WorkingTimeBorderCSS = "8px";
  if (
    WorkingTime === 0 &&
    DownTime === 0 &&
    UnscheduledTime === 0 &&
    IdleTime > 0
  )
    IdleTimeBorderCSS = "8px";
  if (
    WorkingTime === 0 &&
    UnscheduledTime === 0 &&
    IdleTime === 0 &&
    DownTime > 0
  )
    DownTimeBorderCSS = "8px";
  // if (WorkingTime === 0 && DownTime === 0 && IdleTime === 0 && UnscheduledTime > 0) UnscheduledTimeBorderCSS = "8px";
  function handleOpenCollapse() {
    if (!open) {
      // DownTimeWithoutUnscheduledPercent = DownTimePercent;
      // DownTimeWithoutUnscheduled = DownTime;
      // this.parentElement.parentElement.querySelector('.UnscheduledTime').style.width = '0%';
      // this.parentElement.parentElement.querySelector('.UnscheduledTimeOff').classList.remove('d-none');
      // this.parentElement.parentElement.querySelector('.UnscheduledTimeOff').classList.add('d-flex');
    }
    setOpen(!open);
  }

  return (
    <div>
      {TotalProductionTime > 0 && (
        <div
          style={{
            // backgroundColor: 'inherit',
            height: "75px",
            width: "100%",
            backgroundColor: "rgb(58, 58, 58)",
            display: "flex",
            color: "#fff",
          }}
          className="rightBar progress"
        >
          <div
            className="progress-bar bg-success WorkingTime"
            role="progressbar"
            style={{
              backgroundColor: "#198754",
              borderRadius: WorkingTimeBorderCSS,
              width: `${WorkingTimePercent}%`,
            }}
            aria-valuenow={WorkingTimePercent}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <h4
              style={{
                margin: "12px",
                overflow: WorkingTimePercent === 0 ? "hidden" : "visible",
              }}
            >
              Working
            </h4>
            <h5
              style={{
                margin: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {Math.round(WorkingTimePercent)}% | {WorkingTime.toFixed(2)} hours
            </h5>
          </div>
          <div
            className="progress-bar IdleTime"
            role="progressbar"
            style={{
              backgroundColor: "orange",
              borderRadius: IdleTimeBorderCSS,
              width: `${IdleTimePercent}%`,
            }}
            aria-valuenow={IdleTimePercent}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <h4
              style={{
                margin: "12px",
                overflow: IdleTimePercent === 0 ? "hidden" : "visible",
              }}
            >
              Idle
            </h4>
            <h5
              style={{
                margin: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {Math.round(IdleTimePercent)}% | {IdleTime.toFixed(2)} hours
            </h5>
          </div>
          <div
            className="progress-bar bg-secondary DownTime"
            role="progressbar"
            style={{
              borderRadius: DownTimeBorderCSS,
              width: open
                ? `${DownTimePercent}%`
                : `${DownTimeWithoutUnscheduledPercent}%`,
            }}
            aria-valuenow={DownTime}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <h4 style={{ margin: "12px" }}>Offline</h4>
            <h5
              style={{
                margin: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {Math.round(
                open ? DownTimePercent : DownTimeWithoutUnscheduledPercent
              )}
              % |{" "}
              {open
                ? DownTime.toFixed(2)
                : DownTimeWithoutUnscheduled.toFixed(2)}{" "}
              hours
            </h5>
          </div>
          <div
            className="progress-bar UnscheduledProgress UnscheduledTime"
            role="progressbar"
            style={{
              width: open ? `${UnscheduledTimePercent}%` : `2rem`,
              backgroundColor: "black",
              cursor: "pointer",
              borderRadius: !open ? "0px 9px 9px 0px" : 0,
            }}
            title="Enable Unscheduled Time"
            // style="{display: none;} :hover {display: block;} background-color: black;"
            onClick={UnscheduledTime > 0 ? () => handleOpenCollapse() : null}
            aria-valuenow={UnscheduledTimePercent}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <h4
              style={{
                display: open && UnscheduledTime > 0 ? `block` : `none`,
                margin: "12px",
              }}
            >
              Unscheduled
            </h4>
            <h5
              style={{
                display: open && UnscheduledTime > 0 ? `block` : `none`,
                margin: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              ${Math.round(UnscheduledTimePercent)}% |{" "}
              {UnscheduledTime.toFixed(2)} hours
            </h5>
            <AddIcon
              sx={{
                mt: "24px",
                border: "1px solid #000",
                borderRadius: "0px 9px 9px 0px",
              }}
              color={theme.palette.custom.text}
            />
          </div>
          {UnscheduledTime > 0 && open && (
            <div
              className="progress-bar align-items-center UnscheduledTimeOff d-flex"
              style={{
                color: "#fff",
                border: "1px solid #000",
                borderRadius: "0px 9px 9px 0px",
                width: "2rem",
                backgroundColor: "black",
                cursor: "pointer",
              }}
              // style="color: #fff;border-radius: 0px 9px 9px 0px; width: 2rem; background-color: black;"
              role="button"
              title="Disable Unscheduled Time"
              onClick={() => handleOpenCollapse()}
              data-DownTime={DownTime}
              data-DownTimePercent={DownTimePercent}
            >
              <CloseIcon
                sx={{ mt: "24px" }}
                color={theme.palette.custom.text}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
OpsumTotalsComparisonProgress.propTypes = {
  ProductionJson: PropTypes.object.isRequired,
  scheduled_time: PropTypes.number,
};
