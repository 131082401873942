/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setAddHubServerConfig = createAsyncThunk(
  "addHubServerConfig/setAddHubServerConfig",
  async (linking) => {
    return linking;
  }
);

const initialState = {
  addHubServerConfigId: "",
  name: "",
  description: "",
  status: "active",
};

const addHubServerConfigSlice = createSlice({
  name: "addHubServerConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setAddHubServerConfig.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const selectAddHubServerConfig = ({ addHubServerConfig }) =>
  addHubServerConfig;

export default addHubServerConfigSlice.reducer;
