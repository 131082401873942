import React, { useState, useEffect } from "react";
import { Popover, Box } from "@mui/material";
import { SketchPicker } from "react-color";
import { useTheme } from "@mui/material/styles";
import CustomTextField from "./CustomTextField";
import PropTypes from "prop-types";

const hexToRgb = (hex) => {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return { r, g, b, a: 1 };
};

const rgbToHex = (rgb) => {
  const { r, g, b } = rgb;
  return `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
};


const ColorPicker = ({ initialColor, label, onColorChange }) => {
  const theme = useTheme();
  //   const [bgColor, setBgColor] = useState(hexToRgb(initialColor));
  // const [bgColor, setBgColor] = useState(initialColor);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    // setBgColor(hexToRgb(initialColor));
  }, [initialColor]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color) => {
    // setBgColor(color.rgb);
    const hexColor = rgbToHex(color.rgb);
    onColorChange(hexColor);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row",
        width: "100%",
        marginBottom: "16px",
      }}
    >
      <div
        style={{
          backgroundColor: initialColor,
          width: "18%",
          height: "50px",
          border: "1px solid black",
          cursor: "pointer",
          marginRight: "2%",
          marginTop: "4px",
        }}
        onClick={handleClick}
      />
      <CustomTextField
        id="bgColor"
        label={label}
        variant="filled"
        margin="none"
        // value={rgbToHex(bgColor)}
        value={initialColor}
        onChange={(e) => {
          const newColor = e.target.value;
          // setBgColor(hexToRgb(newColor));
          // setBgColor(newColor);
          onColorChange(newColor);
        }}
        sx={{
          width: { xs: "80%", sm: "80%" },
          borderColor: theme.palette.custom.borderColor,
          borderRadius: "11px",
          backgroundColor: theme.palette.custom.light,
        }}
      />

      <Popover
        id="color-picker-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <SketchPicker
          color={hexToRgb(initialColor)}
          onChange={handleColorChange}
        />
      </Popover>
    </Box>
  );
};
ColorPicker.propTypes = {
  initialColor: PropTypes.string,
  label: PropTypes.element,
  onColorChange: PropTypes.string,
};

export default ColorPicker;
