import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

function LastButton({ onClick }) {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: theme.palette.custom.buttonColor,
        borderRadius: "4px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
        // display: "flex",
        // justifyContent: "center",
        width: "46px",
        height: "36px",
        padding: "5px 10px",
        "&:hover": {
          backgroundColor: theme.palette.custom.buttonHoverColor,
          color: theme.palette.custom.buttonHoverTextColor,
          boxShadow: "none",
        },
      }}
    >
      <ArrowBackIosNewIcon
        onClick={onClick}
        sx={{
          color: theme.palette.custom.buttonTextColor,
          fontSize: 24,
          "&:hover": {
            color: theme.palette.custom.buttonHoverTextColor,
          },
        }}
      />
    </Box>
  );
}
LastButton.propTypes = {
  onClick: PropTypes.func,
};

export default LastButton;
