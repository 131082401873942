import React from "react";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from "prop-types";
import StyledTypography from "../common/StyledTypography";

export default function TicketTemplateTableRow({ setSelected, row, labelId }) {
    const theme = useTheme();
    const handleClick = (uid) => {
        setSelected(uid)
    }
    return (
        <TableRow
            hover
            tabIndex={-1}
            key={row.template_header_uid}
            sx={{ cursor: 'pointer' }}
            onClick={() => { handleClick(row.template_header_uid) }}
        >
            <TableCell
                sx={{ height: '100%', color: theme.palette.custom.text, padding: "16px", backgroundColor: theme.palette.custom.backgroundColor }}
                component="th"
                id={labelId}
                scope="row"
            >
                <Box sx={{ textAlign: "center", width: '100%', display: 'flex', flexFlow: 'row', justifyContent: 'left' }}>
                    <StyledTypography variant="subtitle2" >
                        {row.name}</StyledTypography>  </Box>

            </TableCell>
            <TableCell
                sx={{ height: '100%', color: theme.palette.custom.text, padding: "16px", backgroundColor: theme.palette.custom.backgroundColor }}
                component="th"
                id={labelId}
                scope="row"
            >
                <Box sx={{ textAlign: "center", width: '100%', display: 'flex', flexFlow: 'row', justifyContent: 'left' }}>
                    <StyledTypography variant="subtitle2" >
                        {row.description}</StyledTypography>  </Box>

            </TableCell>
        </TableRow>
    );
}
TicketTemplateTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    setSelected: PropTypes.func,
    labelId: PropTypes.string,
};