import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import TemplatesMenu from "./templatesMenu";
import TicketsService from "../api/tickets";
import { TemplateInputs } from "../common/templateInputs";
import Breadcrumbs from "../common/breadcrumbs";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Ticket Dashboard", href: "indexHelpdesk" },
  { id: 2, name: "NewTicket", href: "NewTicket" },
];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.custom.backgroundColor,
    borderRadius: "3px",
    padding: "8px 16px",
    height: "36px",
    minHeight: "36px",
    color: theme.palette.custom.text,
    "&.Mui-selected": {
      color: theme.palette.custom.text,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.custom.buttonColor,
      color: theme.palette.custom.buttonHoverTextColor,
      opacity: "70%",
    },
  })
);

function NewTicket() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const { t } = useTranslation();
  const [selected, setSelected] = useState(undefined);
  const [requirements, setRequirements] = useState(undefined);
  const [newInp, setNewInp] = useState(undefined);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedID, setSelectedID] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [departmentsList, setDepartmentsList] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(undefined);

  const handleSaveTicket = () => {
    const apiKey = localStorage.getItem("apiKey");
    requirements.ticket_template[0].department_id =
      selectedDepartment === undefined ? "" : selectedDepartment;
    requirements.ticket_template[0].status = "new";
    requirements.ticket_template[0].ticket_header_uid = "";
    requirements.ticket_template[0].operation_profile_uid = JSON.parse(
      localStorage.getItem("defaultOrganization")
    ).uid;
    requirements.ticket_template[0].ticket_template_header_uid =
      requirements.ticket_template[0].template_header_uid;
    requirements.ticket_template[0].created_by_person_uid = JSON.parse(
      localStorage.getItem("user")
    ).profileUid;
    delete requirements.ticket_template[0].template_header_uid;
    setRequirements(requirements);
    TicketsService.SaveTicketAPI(requirements.ticket_template[0], apiKey);
  };

  useEffect(() => {
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      setDepartmentsList(null);
      TicketsService.GetOperationDepartment({
        department_id: "",
        operation_profile_uid: "",
        name: "",
        status: "",
        apiKey,
      })
        .then((json) => {
          if (json && json.department && json.department.length > 0) {
            let DepList = [{ id: "", name: "General" }];
            for (let index = 0; index < json.department.length; index++) {
              const RealDepartmentID = json.department[index].department_id;
              DepList.push({
                id: RealDepartmentID,
                name: json.department[index].name,
              });
            }
            setDepartmentsList(DepList);
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const apiKey = localStorage.getItem("apiKey");
    TicketsService.GetTemplateInputs(selected, apiKey).then((json) => {
      if (json) {
        // json.ticket_template[0].status = "new";
        for (let i in json.ticket_template) {
          for (let j in json.ticket_template[i].requirements) {
            json.ticket_template[i].requirements[j].inputs.forEach((input) => {
              if (
                input.config.input_requirement_config_uid.toString() === "6"
              ) {
                input.value = [];
                setRequirements(json);
              } else {
                setRequirements(json);
              }
            });
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (requirements !== undefined) {
      requirements.ticket_template[0].requirements.forEach((requirement) => {
        requirement.inputs.forEach((input) => {
          let formattedID = selectedID.replace("Input", "");
          if (formattedID === input.template_requirement_input_uid.toString()) {
            // console.log("here")
            input.value = selectedValue;
            setRequirements(requirements);
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    if (requirements !== undefined) {
      requirements.ticket_template[0].requirements.forEach((requirement) => {
        requirement.inputs.forEach((input) => {
          let formattedID = selectedID.replace("Input", "");
          if (formattedID === input.template_requirement_input_uid.toString()) {
            console.log(selectedFile);
            input.value = selectedFile;
            setRequirements(requirements);
            console.log(requirements);
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedDepartment(newValue === 0 ? "" : newValue);
  };

  const handlePopulate = (requirements) => {
    if (requirements !== undefined && requirements.count > 0) {
      return (
        <div style={{ padding: "12px" }}>
          <Typography
            sx={{ fontSize: "36px", mb: 0, color: theme.palette.custom.text }}
          >
            {requirements.ticket_template[0].name}
          </Typography>
          <Divider
            sx={{
              my: "10px",
              backgroundColor: theme.palette.custom.borderColor,
            }}
          />

          {requirements.ticket_template[0].requirements.map(
            (requirement, index) => (
              <div
                style={{ alignItems: "center", padding: "12px" }}
                className="row mb-2 ms-2 me-2"
                key={index}
              >
                <div className="col">
                  <Typography
                    sx={{
                      fontSize: "27px",
                      mb: 0,
                      color: theme.palette.custom.text,
                    }}
                  >
                    {requirement.name}
                  </Typography>
                </div>
                <div className="col">
                  <div className="row">
                    <div style={{ padding: "12px" }}>
                      {requirement.inputs.map((inputs, index) => (
                        <TemplateInputs
                          key={index}
                          inputs={inputs}
                          setSelectedID={setSelectedID}
                          selectedValue={selectedValue}
                          setSelectedValue={setSelectedValue}
                          setSelectedFile={setSelectedFile}
                          selectedFile={selectedFile}
                          onChange={(e) => {
                            handleChangeInput(
                              e.target.value,
                              e.target.id,
                              e.target.name,
                              e.target.checked,
                              e.target.getAttribute("data-id")
                            );
                          }}
                        />
                      ))}
                    </div>
                    <Divider
                      sx={{
                        my: "10px",
                        backgroundColor: theme.palette.custom.borderColor,
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          )}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              paddingRight: "12px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                display: "flex",
                justifySelf: "right",
                backgroundColor: theme.palette.custom.borderColor,
                color: "white",
                "&:hover": {
                  backgroundColor: theme.palette.custom.buttonHoverColor,
                  color: theme.palette.custom.buttonHoverTextColor,
                  boxShadow: "none",
                },
              }}
              onClick={() => {
                handleSaveTicket();
              }}
            >
              {t("Save")}
            </Button>
          </div>
        </div>
      );
    }
  };

  const handleChangeInput = (value, id, name, checked, dataID) => {
    let idToFormat = "";
    id === undefined || id === "" ? (idToFormat = name) : (idToFormat = id);
    requirements.ticket_template[0].requirements.forEach((requirement) => {
      requirement.inputs.forEach((input) => {
        let formattedID = idToFormat.replace("Input", "");
        if (formattedID === input.template_requirement_input_uid.toString()) {
          // console.log("true");
          if (input.config.input_requirement_config_uid.toString() === "6") {
            // console.log("trueeee");
            if (checked.toString() === "on") checked = true;
            if (checked.toString() === "off") checked = false;
            input.value.push({
              template_requirement_input_select_uid: dataID,
              value: checked,
            });
            setRequirements(requirements);
          } else {
            setNewInp(value);
            input.value = newInp;
            setRequirements(requirements);
          }
          console.log(requirements);
        }
      });
    });
  };
  const handleBreadCrumbsArray = (breadcrumbs) => {
    if (breadcrumbs.length === 3) {
      return breadcrumbs;
    }
    // breadcrumbs.push({ id: 1, name: device.DeviceName, href: `/device?device=${device.DeviceID}` })
    return breadcrumbs;
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box sx={{ width: "100%" }}>
        {" "}
        {/* <HomePagesMenu pageName={'Maintenance'} /> */}
        <Breadcrumbs breadcrumbs={handleBreadCrumbsArray(breadcrumbs)} />
        <Typography
          sx={{
            fontSize: "45px",
            mb: 0,
            color: theme.palette.custom.borderColor,
          }}
          variant="h2"
          gutterBottom
        >
          {/* {capitalize(t('Devices'))} */}
          {t("NewTicket")}
        </Typography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {departmentsList && departmentsList.length > 0
              ? departmentsList.map((item, index) => (
                  <StyledTab
                    label={item.name}
                    {...a11yProps(Number(item.id))}
                    key={index}
                  />
                ))
              : null}
          </Tabs>
        </Box>
        {departmentsList && departmentsList.length > 0
          ? departmentsList.map((item, index) => (
              <CustomTabPanel value={value} key={index} index={index}>
                <Box
                  sx={{
                    p: "12px",
                    borderRadius: "16px",
                    border: `1px solid ${theme.palette.custom.borderColor}`,
                  }}
                  style={{
                    minHeight: "90vh",
                    height: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: { xs: "block", sm: "center" },
                      minHeight: "64px",
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: "block", sm: "flex", width: "100%" },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "45px",
                            mb: 0,
                            color: theme.palette.custom.text,
                          }}
                          gutterBottom
                        >
                          {item.name}:
                        </Typography>
                        <TemplatesMenu
                          setSelected={setSelected}
                          department={Number(item.id)}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Divider
                    sx={{
                      my: "10px",
                      backgroundColor: theme.palette.custom.borderColor,
                    }}
                  />
                  {selected !== undefined ? (
                    handlePopulate(requirements)
                  ) : (
                    <></>
                  )}
                </Box>
              </CustomTabPanel>
            ))
          : null}
      </Box>
    </Box>
  );
}

export default NewTicket;
