import { config } from "../config";
import Utils from "../utils/utils";

class HubServerInfoService extends Utils.EventEmitter {
  GetHUBServerInfo(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          hub_server_info_id: inputData.hub_server_info_id,
          name: inputData.name,
          description: "",
          status: inputData.status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/config/hub_server_info/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetHUBServerInfoAttribute(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          hub_server_info_attribute_id: inputData.hub_server_info_attribute_id,
          hub_server_info_id: inputData.hub_server_info_id,
          name: inputData.name,
          description: "",
          value: "",
          data_type: "",
          status: inputData.status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://example.com",
        "Access-Control-Allow-Credentials": true,
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(
        `${config.url.api_ip}/ns-core/config/hub_server_info/attributes/get`,
        {
          method: "post",
          headers,
          body,
        }
      )
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetHUBServerInfo(
    hubServerInfoId = "",
    name = "",
    description = "",
    status = "active",
    apiKey
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            hub_server_info_id: hubServerInfoId,
            name,
            description,
            status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/config/hub_server_info/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}

const instance = new HubServerInfoService();

export default instance;
