import React from "react";
import { Box } from "@mui/material";
import OpsumTotalSingle from "./opsumHistoryTotalSingleComponent";
import PropTypes from "prop-types";


export default function OpsumHistoryTotals({
  opsumTotalsComparisonList,
  deviceHistoryTotals,
  compareDeviceHistoryTotals,
  arrowSize,
  sizeSmall,
}) {
  return (
    <Box
      sx={{
        display: { xs: "block", sm: "flex" },
        justifyContent: "space-between",
      }}
    >
      {opsumTotalsComparisonList.map((e) => (
        <span key={e.tittle} title={`Previous: ${compareDeviceHistoryTotals ? Number(compareDeviceHistoryTotals[e.valueName]).toFixed(2): "N/A"}`}>
        <OpsumTotalSingle
          tittle={e.tittle}
          value={
            deviceHistoryTotals
              ? deviceHistoryTotals[e.valueName]
              : (0).toFixed(2)
          }
          valueLabel={e.valueLabel}
          compareComponent={
            compareDeviceHistoryTotals
              ? {
                  newValue: deviceHistoryTotals[e.valueName],
                  OldValue: compareDeviceHistoryTotals[e.valueName],
                  reverse: e.compareReverse,
                  fontSize: arrowSize || 30,
                }
              : undefined
          }
          sizeSmall={sizeSmall}
        />
        </span>
      ))}
    </Box>
  );
}
OpsumHistoryTotals.propTypes = {
  opsumTotalsComparisonList: PropTypes.array,
  deviceHistoryTotals: PropTypes.array,
  compareDeviceHistoryTotals: PropTypes.array,
  arrowSize: PropTypes.number,
  sizeSmall: PropTypes.bool,
};
