import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import ProfilePictureSelect from "../common/ProfileSelect/button";
import Button from "@mui/material/Button";
import ProfileService from "../api/profile";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getCookie } from "../utils/cookies";
import { getUrlVars, validateEmail } from "../utils/systemInfo";
import SelectFields from "../common/selectFields";
import CustomTextField from "../common/CustomTextField";
// import OrganizationsInput from "../common/Layout/organizationsInput";
import { showMessage } from "../store/messageSlice";
import FakeUser from "../FakeUser.png";
import ProfilePageOrganizations from "./profilePageOrganizations";
import OrganizationService from "../api/organization";

// function handleDelete(name, lName, user) {
//   ProfileService.SetUserProfile(user, name, lName, "removed");
// }

export default function Profile() {
  const theme = useTheme();
  const { t } = useTranslation();
  const userProfileId = getUrlVars()["id"];
  const [userProfileUid, setUserProfileUid] = useState("");
  const organziation = useSelector(({ organziation }) => organziation);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [lName, setLName] = useState("");
  const [pass, setPass] = useState("");
  const [org, setOrg] = useState("");
  const [uTypeId, setUTypeId] = useState(getUrlVars()["type"]);
  const [mobile, setMobile] = useState("");
  const [mobileContactUid, setMobileContactUid] = useState("");
  const [email, setEmail] = useState("");
  const [emailContactUid, setEmailContactUid] = useState("");
  const [url, setUrl] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [profileSaved, setProfileSaved] = useState(false);
  const [passwordSaved, setPasswordSaved] = useState(false);
  const [pictureSaved, setPictureSaved] = useState(false);
  const [mobileSaved, setMobileSaved] = useState(false);
  const [emailSaved, setEmailSaved] = useState(false);
  const [userTypeSaved, setUserTypeSaved] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    const apiKey = organziation?.api_key_list?.[0]?.key;
    ProfileService.GetUserType({
      user_type_uid: "",
      apiKey,
    }).then((jsonTypes) => {
      setUserTypes(
        jsonTypes?.app_user_type?.map((e) => ({
          value: e.user_type_uid,
          label: e.name,
        }))
      );
    });
    ProfileService.GetUserProfile({
      userProfileID: userProfileId,
      userProfileUID: "",
      status: "active",
      apiKey,
    }).then((json) => {
      const userJSON = json.person_profile[0];
      if (userJSON) {
        setUserProfileUid(userJSON.profile_uid);
        const mobileContact = userJSON.contact?.find(
          (c) => c.contact_type === "tel_mobile" && c.primary_contact
        );
        setMobile(mobileContact?.value);
        setMobileContactUid(mobileContact?.contact_uid);
        const emailContact = userJSON.contact?.find(
          (c) => c.contact_type === "email_address" && c.primary_contact
        );
        setEmail(emailContact?.value);
        setEmailContactUid(emailContact?.contact_uid);

        setName(userJSON?.firstname);
        setLName(userJSON?.lastname);
        setOrg(
          JSON.parse(localStorage.getItem("defaultOrganization")).full_name
        );
        setUrl(
          json.person_profile?.[0]?.image?.find(
            (img) =>
              img.image_type === "profile_image" && img.status === "active"
          )?.public_url
        );
      }
    });
    // ProfileService.GetUserPassword({ person_profile_id: userProfileId, apiKey }).then((json) => {
    //   setPass(json.person_login[0]?.password);
    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleSave() {
    const apiKey = organziation?.api_key_list?.[0]?.key;
    if (document.getElementById("file").files.length > 0) {
      ProfileService.SetUserProfilePicture(
        getCookie("User_ProfileID"),
        "pfpForm",
        apiKey
      ).then((response) => {
        if (response?.data?.count >= 1) setPictureSaved(true);
      });
    } else {
      setPictureSaved(true);
    }
    ProfileService.SetUserProfile(
      userProfileId,
      name,
      lName,
      "active",
      uTypeId
    ).then((response) => {
      if (response?.data?.count >= 1) setProfileSaved(true);
    });
    if (pass) {
      ProfileService.SetUserPassword("", userProfileId, pass).then(
        (response) => {
          if (response?.data?.count >= 1) setPasswordSaved(true);
        }
      );
    } else {
      setPasswordSaved(true);
    }
    if (uTypeId && uTypeId !== getUrlVars()["type"] && organziation) {
      OrganizationService.SetOperationPersonLink(
        {
          operation_id: organziation.uid,
          person_id: userProfileId,
          user_type_uid: uTypeId,
          auto_assign_ticket: "0",
          status: "link",
        },
        apiKey
      ).then((json) => {
        if (json) {
          setUserTypeSaved(true);
        }
      });
    } else {
      setUserTypeSaved(true);
    }
    ProfileService.SetUserContact({
      contact_uid: mobileContactUid,
      person_profile_uid: userProfileUid,
      contact_type: "tel_mobile",
      value: mobile,
    }).then((response) => {
      if (response?.data?.count >= 1) {
        setEmailSaved(true);
      }
    });
    ProfileService.SetUserContact({
      contact_uid: emailContactUid,
      person_profile_uid: userProfileUid,
      contact_type: "email_address",
      value: email,
    }).then((response) => {
      if (response?.data?.count >= 1) {
        setMobileSaved(true);
      }
    });
  }
  useEffect(() => {
    if (
      profileSaved &&
      passwordSaved &&
      pictureSaved &&
      emailSaved &&
      mobileSaved &&
      userTypeSaved
    ) {
      dispatch(
        showMessage({
          message: t("successfully saved"), //text or html
          autoHideDuration: 30000, //ms
          anchorOrigin: {
            vertical: "top", //top bottom
            horizontal: "center", //left center right
          },
          variant: "success", //success error info warning null
        })
      );
      setProfileSaved(false);
      setPasswordSaved(false);
      setPictureSaved(false);
      setMobileSaved(false);
      setEmailSaved(false);
      setUserTypeSaved(false);
      navigate("/users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profileSaved,
    passwordSaved,
    pictureSaved,
    emailSaved,
    mobileSaved,
    userTypeSaved,
  ]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        height: "100%",
      }}
    >
      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
        <Typography
          sx={{
            fontSize: "45px",
            mb: 0,
            color: theme.palette.custom.borderColor,
          }}
          variant="h2"
          gutterBottom
        >
          {t("Profile")}
        </Typography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        id="profileBox"
        sx={{
          display: { xs: "block", sm: "flex" },
          width: "100%",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              width: "100%",
              marginLeft: ".5rem",
              marginRight: ".5rem",
            }}
          >
            <ProfilePictureSelect
              frmID={"pfpForm"}
              layID={"layPfpSelect"}
              divID={"pfpDiv"}
              fileID={"file"}
              header={"Change Profile Picture"}
              label={"Profile Picture"}
              url={url || FakeUser}
            ></ProfilePictureSelect>
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              width: "100%",
              gap: "1rem",
              marginLeft: ".5rem",
              marginRight: ".5rem",
            }}
          >
            <CustomTextField
              id="txtPass"
              label={t("Password")}
              variant="filled"
              type={showPassword ? "text" : "password"}
              value={pass}
              onChange={(e) => {
                setPass(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                borderColor: theme.palette.custom.borderColor,
              }}
            />
            <CustomTextField
              id="txtFirstName"
              label={t("First Name")}
              variant="filled"
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{
                width: "100%",
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
            />
            <CustomTextField
              id="txtLastName"
              label={t("Last Name")}
              variant="filled"
              value={lName}
              onChange={(e) => setLName(e.target.value)}
              sx={{
                width: { xs: "100%", sm: "100%" },
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              width: "100%",
              gap: "1rem",
              marginLeft: ".5rem",
              marginRight: ".5rem",
              alignSelf: "start",
            }}
          >
            <CustomTextField
              id="txtOrg"
              disabled
              label={t("Organization")}
              variant="filled"
              value={org}
              sx={{
                width: { xs: "100%", sm: "100%" },
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
            />
            <SelectFields
              title={t("User Type")}
              value={uTypeId}
              fields={userTypes}
              onChange={(value) => {
                setUTypeId(value);
              }}
            />
            {/* <OrganizationsInput userProfileId={userProfileId} readOnly /> */}
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              width: "100%",
              gap: "1rem",
              marginLeft: ".5rem",
              marginRight: ".5rem",
              alignSelf: "start",
            }}
          >
            <CustomTextField
              id="txtEmail"
              label={t("Email Address")}
              variant="filled"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                width: { xs: "100%", sm: "100%" },
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
              error={email !== "" && emailError}
              helperText={emailError ? t("Email not Valid") : null}
              onBlur={() => {
                const errorEmail = validateEmail(email);
                if (errorEmail) {
                  // set value displayed in the textbox
                  setEmailError(false);
                } else {
                  setEmailError(true);
                }
              }}
            />
            <CustomTextField
              id="txtMobile"
              label={t("Mobile Number")}
              variant="filled"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              sx={{
                width: { xs: "100%", sm: "100%" },
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+</InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          flexDirection: "row",
          justifyContent: "end",
          width: "100%",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Button
          variant="contained"
          sx={{
            float: "right",
            backgroundColor: theme.palette.custom.borderColor,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: "none",
            },
          }}
          onClick={handleSave}
        >
          {t("Save")}
        </Button>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <ProfilePageOrganizations userProfileId={userProfileId} />
    </Box>
  );
}
