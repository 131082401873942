/* eslint-disable import/no-named-as-default-member */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { config } from "./config";

const loadResources = async (locale) => {
  const method = "POST";
  const body = JSON.stringify({
    get: {
      language_id: "",
      name: "",
      code: locale,
      status: "active",
      compact: true,
      variable_name: true,
    },
  });
  const headers = {
    "Content-Type": "application/json",
    apiSession: localStorage.getItem("auth_token"),
    apiKey: localStorage.getItem("apiKey"),
  };
  return await fetch(
    `${config.url.api_ip}/ns-core/config/language/dictionary/translations/get`,
    {
      method,
      headers,
      body,
    }
  )
    .then((response) => response.json())
    .then((json) => {
      // const data = json.language_profile[0].translation;
      // const jsonObject = {};
      // data.forEach((element) => {
      //   Object.assign(jsonObject, { [element.variable_name]: element.value });
      // });
      return json;
    })
    .catch((error) => {
      if (process.env.NODE_ENV === "development")
        console.log("translationError", error);
    });
};
const backendOptions = {
  expirationTime: 7,
  loadPath: "{{lng}}|{{ns}}",
  requestOptions: {
    cache: "no-store",
  },
  request: (options, url, payload, callback) => {
    try {
      const [lng] = url.split("|");
      loadResources(lng).then((response) => {
        callback(null, {
          data: response,
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("i18nextLng") || "en",
    interpolation: {
      escapeValue: false,
    },
    backend: backendOptions,
    //debug: false,
    debug: process.env.NODE_ENV === "development",
  });

export default i18n;
