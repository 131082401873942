import React from "react";
import "./scheduledTimeGraphic.css"

function TimeSchedulerHTML() {
return(
    <div className="graphicContainer">
    <div className="row1" title="Calendar Time (CT)">
        <span className="gridRows">Calendar Time (CT)</span>
    </div>
    <div className="row2">
        <span className="gridRows" title="Scheduled Time (ST)">Scheduled Time (ST)</span>
    </div>
    <div className="col2">
        <span className="gridCols" title="Unscheduled (UT)">Unscheduled (UT)</span>
    </div>
    <div className="row3">
        <span className="gridRows" title="Available Time (AT)">Available Time (AT)</span>
    </div>
    <div className="col3">
        <span className="gridCols" title="Downtime (DT)">Downtime (DT)</span>
    </div>
    <div className="row4">
        <span className="gridRows" title="Operating Time (OT)">Operating Time (OT)</span>
    </div>
    <div className="col4">
        <div className="col41">
            <span className="gridCols" title="Standby (SB)">Standby (SB)</span>
        </div>
        <div className="col42">
            <div className="col421">
                <span className="gridCols" id="SBO" title="Operating Standby (SBO)">Operating Standby (SBO)</span>
            </div>
            <div className="col422">
                <span className="gridCols" id="SBE" title="External Standby (SBE)">External Standby (SBE)</span>
            </div>
        </div>
    </div>
    <div className="row5">
        <span className="gridRows" title="Working Time (WT)">Working Time (WT)</span>
    </div>
    <div className="col5">
        <span className="gridCols" title="Operating Delay (OD)">Operating Delay (OD)</span>
    </div>
    <div className="row6">
        <span className="gridRows" title="Productive Time (PT)">Productive Time (PT)</span>
    </div>
    <div className="col6">
        <span className="gridCols" title="Non-Productive (NP)">Non-Productive (NP)</span>
    </div>
</div>
)
}

export default function ScheduledTimeGraphic() {
    return (
        <TimeSchedulerHTML />
    )
}