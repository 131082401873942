import React,{ useState, useEffect } from "react";
import { Box, Divider, Typography, capitalize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import DialpadIcon from "@mui/icons-material/Dialpad";
import HelpdeskIconButton from "../common/helpdeskIconButton";
import GiteaIconButton from "../common/giteaIconButton";
import ProjectsIconButton from "../common/projectsIconButton";
import OperationService from "../api/operation";
import PersonService from "../api/person";
import OrganizationService from "../api/organization";
import utils from "../utils/utils";
import { UserPermissionConstants } from "../utils/permissions";

const StyledNumbersText = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    fontSize: { xs: "2rem", sm: "2.5rem" },
    color: theme.palette.custom.text,
    fontWeight: "bold",
  })
);
const StyledText = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    fontSize: { xs: "1rem", sm: "!.5rem" },
    color: theme.palette.custom.text,
    marginTop: "2%",
  })
);
const StyledDivider = styled((props) => <Divider {...props} />)(
  ({ theme }) => ({
    width: "60%",
    marginTop: "2%",
    marginLeft: "18%",
    backgroundColor: theme.palette.custom.borderColor,
  })
);
function HomeGrafanaPage() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [machinesCount, setMachinesCount] = useState(null);
  const [hoursCount, setHoursCount] = useState(null);
  const [usersCount, setUsersCount] = useState(null);
  const [organizationsCount, setOrganizationsCount] = useState(null);
  const permissions = useSelector(({ permissions }) => permissions);

  useEffect(() => {
    OperationService.GetOperationProfileSummary("1")
      .then((json) => {
        setMachinesCount(json?.operation_summary?.device?.total);
        const monthly_data = json?.operation_summary?.device?.total * 24 * 30;
        setHoursCount(
          `${monthly_data
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} hours`
        );
      })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
    PersonService.GetUserCount({ apiKey: "" })
      .then((count) => {
        setUsersCount(count);
      })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
    const api_key_nerospec_core = "MTIxMnwxfDN8MjE5OS0wMS0wMSAwMDowMDowMA";
    OrganizationService.GetOperationProfile({
      operation_profile_id: "",
      operation_full_name: "",
      apiKey: api_key_nerospec_core,
    })
      .then((json) => {
        setOrganizationsCount(json?.count);
      })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleClickButton(name) {
    switch (name) {
      case "projects":
        // if (
        //   utils.hasPermission(
        //     UserPermissionConstants.VIEW_PROJECTS,
        //     permissions
        //   )
        // )
        //   navigate("");
        break;
      case "helpdesk":
        if (
          utils.hasPermission(
            UserPermissionConstants.VIEW_HELPDESK,
            permissions || []
          )
        )
          navigate("");
        break;
      case "gitea":
        // if (
        //   utils.hasPermission(
        //     UserPermissionConstants.VIEW_GITEA_SERVERS,
        //     permissions
        //   )
        // )
        //   navigate("");
        break;
      default:
        break;
    }
  }
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
        <Typography
          sx={{
            fontSize: "45px",
            mb: 0,
            color: theme.palette.custom.borderColor,
          }}
          variant="h2"
          gutterBottom
        >
          {capitalize(t("SIMAC"))}
        </Typography>
        <Typography
          sx={{
            ml: { xs: 0, sm: 1 },
            mt: { xs: 0, sm: 1 },
            fontSize: { xs: "1rem", sm: "1.5rem" },
            color: theme.palette.custom.text,
          }}
          variant="h4"
          gutterBottom
        >
          {t("HomeSubHeader")}
        </Typography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          alignItems: { xs: "block", sm: "center" },
          height: "fit-content",
        }}
      >
        <HelpdeskIconButton
          disabled={
            !utils.hasPermission(
              UserPermissionConstants.VIEW_HELPDESK,
              permissions || []
            )
          }
          onClick={() => handleClickButton("helpdesk")}
        />
        <ProjectsIconButton
          disabled={
            !utils.hasPermission(
              UserPermissionConstants.VIEW_PROJECTS,
              permissions || []
            )
          }
          onClick={() => handleClickButton("projects")}
        />
        <GiteaIconButton
          disabled={
            !utils.hasPermission(
              UserPermissionConstants.VIEW_GITEA_SERVERS,
              permissions || []
            )
          }
          onClick={() => handleClickButton("gitea")}
        />
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        sx={{
          textAlign: "center",
          mt: "100px",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "2rem", sm: "2.5rem" },
            color: theme.palette.custom.text,
          }}
          variant="h2"
          gutterBottom
        >
          {t("SimacStats")}
        </Typography>

        <StyledDivider />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          alignItems: { xs: "block", sm: "center" },
          height: "fit-content",
          mt: "30px",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <LocalShippingIcon
            sx={{
              color: theme.palette.custom.text,
              fontSize: 150,
            }}
          />
          <StyledNumbersText variant="h4">{machinesCount}</StyledNumbersText>
          <StyledText variant="h4">{t("MachinesDevices")}</StyledText>
        </Box>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <SignalCellularAltIcon
            sx={{
              color: theme.palette.custom.text,
              fontSize: 150,
            }}
          />
          <StyledNumbersText variant="h4">{hoursCount}</StyledNumbersText>
          <StyledText variant="h4">{t("MonthlyData")}</StyledText>
        </Box>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <PeopleOutlineIcon
            sx={{
              color: theme.palette.custom.text,
              fontSize: 150,
            }}
          />
          <StyledNumbersText variant="h4">{usersCount}</StyledNumbersText>
          <StyledText variant="h4">{t("Users")}</StyledText>
        </Box>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <DialpadIcon
            sx={{
              color: theme.palette.custom.text,
              fontSize: 150,
            }}
          />
          <StyledNumbersText variant="h4">
            {organizationsCount}
          </StyledNumbersText>
          <StyledText variant="h4">{t("Organizations")}</StyledText>
        </Box>
      </Box>
      <StyledDivider />
    </Box>
  );
}

export default HomeGrafanaPage;
