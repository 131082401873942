import React from 'react';
import "./button.css";
import { useTheme } from "@mui/material/styles";
import PropTypes from 'prop-types';
function handleFileSelect(e, id) {
  let file = e.target.files[0];
  let reader = new FileReader();
  reader.onloadend = function () {
    document.getElementById(
      id
    ).style.backgroundImage = `url("${reader.result}")`;
  };
  if (file) reader.readAsDataURL(file);
}
// function nsProfilePictureSelect_UpdateProfilePicture(url,imgElementID = "#ProfilePictureDiv") {
//     if (imgElementID[0] !== "#") imgElementID = "#" + imgElementID; // Adds # to the beginning of the ID
//     document.querySelector(imgElementID).style.backgroundImage = "url(" + url + ")";
// }
ProfilePictureSelect.propTypes = {
  frmID: PropTypes.string,
  layID: PropTypes.string,
  divID: PropTypes.string,
  fileID: PropTypes.string,
  label: PropTypes.string,
  header: PropTypes.string,
  url: PropTypes.string,
};
function ProfilePictureSelect({
  frmID,
  layID,
  divID,
  fileID,
  label,
  header,
  url,
}) {
  const theme = useTheme();
  return (
    <>
      <form id={frmID} action="" method="post" encType="multipart/form-data">
        <div id={layID}>
          <label htmlFor={fileID} className="form-label w-100">
            <span
              className="Multilingual"
              style={{ color: theme.palette.custom.text, fontSize: "14.4px" }}
            >
              {label}:
            </span>

            <div className="ml-reseller-add-logo">
              <div
                id={divID}
                className="imgUpload imgUpload-hover"
                style={{ backgroundImage: `url(${url})` }}
              >
                <span>
                  <img src="../../../public/download.svg" alt=""></img>
                  <p className="Multilingual">{header}</p>
                </span>
              </div>
            </div>
          </label>
          <input
            hidden
            type="file"
            id={fileID}
            name="file"
            onChange={(event) => {
              handleFileSelect(event, divID);
            }}
          />
        </div>
      </form>
    </>
  );
}

export default ProfilePictureSelect;
