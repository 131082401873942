import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropTypes from 'prop-types';

export default function BasicBreadcrumbs({ breadcrumbs }) {

    const { t } = useTranslation();
    const theme = useTheme();


    return (
        <div role="presentation" style={{ width: '100%' }} >
            <Breadcrumbs aria-label="breadcrumb" sx={{
                color: theme.palette.custom.text,
                width: '100%'
            }}
                separator={<NavigateNextIcon fontSize="small" />}
            >
                {breadcrumbs && breadcrumbs.length > 0 ? breadcrumbs.map((item, index) => (
                    <Link
                        key={index}
                        // onClick={() => handleClick(item.href)}
                        sx={{
                            color: theme.palette.custom.text
                        }} underline="hover" href={item.href}>
                        {item.noTranslation ? item.name : t(item.name)}
                    </Link>
                )) : null}
            </Breadcrumbs>
        </div>
    );
}
BasicBreadcrumbs.propTypes = {
    breadcrumbs: PropTypes.array,
};