/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setLinkedDevices = createAsyncThunk('linkedDevices/setLinkedDevices', async (inputData) => {
  const data = inputData.map(device => ({
    ...device,
    DeviceID: device.device_id,
  }))
  return data;
});

const initialState = []


const linkedDevicesSlice = createSlice({
  name: 'linkedDevices',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setLinkedDevices.fulfilled, (state, action) => action.payload);
  },
});

export const selectLinkedDevices = ({ linkedDevices }) => linkedDevices;

export default linkedDevicesSlice.reducer;
