import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "../common/breadcrumbs";
import { getUrlVars } from "../utils/systemInfo";
import OrganizationSubOrganizationTab from "./organizationSubOrganizationTab";
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    width: "160px",
    backgroundColor: theme.palette.custom.backgroundColor,
    borderRadius: "11px",
    height: "36px",
    minHeight: "36px",
    color: theme.palette.custom.text,
    "&.Mui-selected": {
      color: theme.palette.custom.text,
      backgroundColor: theme.palette.custom.backgroundColor,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      color: theme.palette.primary.main,
    },
  })
);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Organizations", href: "organizations" },
  { id: 2, name: "Organization Config", href: "organizationConfig" },
];
function OrganizationCofigPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  // const OrganizationId = String(getUrlVars()["id"]);
  const OrganizationName = String(getUrlVars()["name"]).replace("%20", " ");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  async function onSaveClick() {
    // const dashboardToSave = {
    //     grafana_dashboard_id: dashboard.grafana_dashboard_id,
    //     name,
    //     name_backend: nameBackend,
    //     description,
    //     name_type: nameType,
    //     name_tab: nameTab,
    //     status,
    //     api_key_current: organziation?.api_key_list?.[0]?.key
    // }
    // const saved = await handleSave(dashboardToSave);
    // if (saved) {
    //     navigate('/grafanaBackend');
    // }
  }
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{ height: "100%" }}
    >
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
        <Typography
          sx={{
            fontSize: "45px",
            mb: 0,
            color: theme.palette.custom.borderColor,
          }}
          gutterBottom
        >
          {OrganizationName}:
        </Typography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.custom.borderColor,
          },
        }}
        value={tabValue}
        onChange={handleTabChange}
        textColor="secondary"
      >
        <StyledTab label={t("SubOrganizations")} />
      </Tabs>
      {tabValue === 0 && <OrganizationSubOrganizationTab />}
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.custom.borderColor,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: "none",
            },
          }}
          onClick={onSaveClick}
        >
          {t("Save")}
        </Button>
      </Box>
    </Box>
  );
}

export default OrganizationCofigPage;
