import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import StyledTypography from "../common/StyledTypography";
import ProfileService from "../api/profile";
import { useSelector } from "react-redux";


export default function MachineCommentTableRow({ row, labelId }) {
    const organziation = useSelector(({ organziation }) => organziation);
    const apiKey = organziation?.api_key_list?.[0]?.key;
    const theme = useTheme();
    const [person, setPerson] = useState("N/A");
    useEffect(() => {
        ProfileService.GetUserProfile({
            profile_id: row.created_by_person_id,
            status: "active",
            apiKey: apiKey
        }).then((json) => {
            let profile = json.person_profile.filter((item) => item.profile_id.toString() === row.created_by_person_id.toString());
            setPerson(profile[0].firstname + " " + profile[0].lastname);
        });
    });

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={Math.floor(Math.random() * 1000000000)} sx={{ cursor: "pointer" }}>
            <TableCell sx={{ height: "100%", color: theme.palette.custom.text }} component="th" id={labelId} scope="row" padding="none">
                <Box sx={{ textAlign: "center", width: "100%", display: "flex", flexFlow: "row" }}>
                    <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
                        {person}
                    </StyledTypography>
                </Box>
            </TableCell>
            <TableCell sx={{ height: "100%", color: theme.palette.custom.text }} component="th" id={labelId} scope="row" padding="none">
                <Box sx={{ textAlign: "center", width: "100%", display: "flex", flexFlow: "row" }}>
                    <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
                        {row.created_timestamp}
                    </StyledTypography>
                </Box>
            </TableCell>
            <TableCell sx={{ height: "100%", color: theme.palette.custom.text }} component="th" id={labelId} scope="row" padding="none">
                <Box sx={{ textAlign: "center", width: "100%", display: "flex", flexFlow: "row" }}>
                    <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
                        {row.comment}
                    </StyledTypography>
                </Box>
            </TableCell>
        </TableRow>
    );
}
MachineCommentTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    labelId: PropTypes.string,
};
