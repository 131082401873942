import React from 'react';
import { styled } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

const StyledIconButton = styled((props) => <IconButton  {...props} />)(
    ({ theme }) => ({
        color: theme.palette.custom.buttonTextColor,
        backgroundColor: theme.palette.custom.buttonColor,
        '&:hover': {
            backgroundColor: theme.palette.custom.buttonHoverColor,
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: 'none',
        },
    }),
);
function CollapseButton({ handleOpenCollapse, open }) {
    return (
        <StyledIconButton
            aria-label="expand row"
            size="small"
            onClick={handleOpenCollapse}
        >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </StyledIconButton>
    );
}
CollapseButton.propTypes = {
    handleOpenCollapse: PropTypes.func,
    open: PropTypes.bool,
};

export default CollapseButton;
