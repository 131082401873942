/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setDeviceGroup = createAsyncThunk(
  "deviceGroup/setDeviceGroup",
  async (data) => {
    return data;
  }
);

const initialState = {
  person_device_group_id: "",
  operation_uid: "",
  person_id: "",
  name: "",
  description: "",
  physical_address:"",
  status: "active",
};

const deviceGroupSlice = createSlice({
  name: "deviceGroup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setDeviceGroup.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const selectDeviceGroup = ({ deviceGroup }) =>
  deviceGroup;

export default deviceGroupSlice.reducer;
