import { config } from "../config";
import Utils from "../utils/utils";
import { JavaScriptDateToDelphiDate } from "../utils/systemInfo";

class DevicesService extends Utils.EventEmitter {
  getDevices(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          device_profile_id: "",
          device_uid: inputData.DeviceID,
          name: "",
          serial: "",
          device_type: inputData.DeviceType,
          device_type_id: "",
          source: inputData.Source,
          device_oem_operation_profile_uid: "",
          machine_type: "",
          data_summary: inputData.Live ? inputData.Live : false,
          history: {
            output: inputData.History ? inputData.History : false,
            device_dst_from: inputData.HistoryFromDST,
            device_dst_to: inputData.HistoryToDST,
          },
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/device/profile/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetDevicesCount(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          device_profile_id: "",
          device_uid: inputData.DeviceID,
          name: "",
          serial: "",
          device_type: inputData.DeviceType,
          device_type_id: "",
          source: inputData.Source,
          device_oem_operation_profile_uid: "",
          machine_type: "",
          data_summary: inputData.Live ? inputData.Live : false,
          history: {
            output: inputData.History ? inputData.History : false,
            device_dst_from: inputData.HistoryFromDST,
            device_dst_to: inputData.HistoryToDST,
          },
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/device/profile/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          let Organization_Total_Machines = 0;
          if (json.success === true && json.count > 0)
            Organization_Total_Machines = json.count;
          resolve(Organization_Total_Machines);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetDeviceProfile(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            device_profile_id: inputData.device_profile_id,
            device_uid: inputData.device_uid,
            serial: inputData.serial,
            name: inputData.name,
            oem: inputData.oem,
            device_type: inputData.device_type,
            nerohub: inputData.nerohub,
            device_type_id: inputData.device_type_id,
            device_oem_operation_profile_uid:
              inputData.device_oem_operation_profile_uid,
            machine_type: inputData.machine_type,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/device/profile/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetDeviceHistoryLatest(inputData) {
    if (inputData.channels.length === 0) {
      inputData.channels.push("battery_low", "value", "is_visible", "din0"); // Default Channels for nerospec devices
      inputData.channels.push(
        "total_out",
        "total_in",
        "temperature",
        "rssi",
        "period_out",
        "period_in",
        "battery_level"
      ); // Channels for nngen2_people_counter1
    }
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          device: inputData.devices,
          channel: inputData.channels,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/iot/history/latest/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetDeviceMuteConfig(inputData) {
    function GetDeviceMuteConfig() {
      let MuteValue = -1;
      let dateObj = new Date();
      dateObj.setDate(dateObj.getDate() + inputData.MuteDays);
      if (inputData.mute_device)
        MuteValue = JavaScriptDateToDelphiDate(dateObj);

      return {
        section_tag: "alerts_basic",
        last_change_dst: JavaScriptDateToDelphiDate(new Date()),
        values: [
          {
            key: "muted_dst",
            typ: 2,
            val: MuteValue,
          },
          {
            key: "tz",
            typ: 1,
            val: -new Date().getTimezoneOffset(),
          },
        ],
      };
    }
    let device_config = [];
    device_config.push(GetDeviceMuteConfig());
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: {
          devices: [
            {
              address: inputData.device_profile_serial,
              config: device_config,
            },
          ],
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/person/device/config/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetDeviceConfig(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          devices: [
            {
              device_id: inputData.device_profile_id,
            },
          ],
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/person/device/config/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetPersonDeviceConfig(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          devices: [
            {
              device_id: inputData.deviceID,
            },
          ],
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/person/device/config/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        //.post(`${config.url.api_ip}/ns-core/device/maintenance/schedule/get`, body, headers)

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  getDeviceGroups(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          person_device_group_id: "",
          operation_uid: inputData.operation_uid || "",//"1",
          person_id: inputData.person_id || "",//"3",
          name: "",
          status: "",
          updated_timestamp: {
            f: "",
            t: ""
          }
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/person/device/group/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetDeviceGroup(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            person_device_group_id: inputData.person_device_group_id,
            operation_uid: inputData.operation_uid,
            person_id: inputData.person_id,
            name: inputData.name,
            description: inputData.description,
            physical_address: inputData.physical_address,
            status: inputData.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/person/device/group/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  getDeviceGroupLinkedDevices(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          person_device_group_link_id: "",//empty
          person_device_group_id: inputData.deviceGroupId || "",//"1",
          device_id: "",
          dst: {
            f: "",
            t: ""
          }
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/person/device/group/link/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  LinkDeviceToDeviceGroup(inputData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            person_device_group_link_id: inputData.linkId || "",
            person_device_group_id: inputData.deviceGroupId,
            device_id: inputData.DeviceID,
            status: inputData.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: inputData.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/person/device/group/link/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}

const instance = new DevicesService();

export default instance;
