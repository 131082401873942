import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Breadcrumbs from '../common/breadcrumbs';
import { useNavigate } from "react-router-dom";
// import { setHubServerConfig } from "../store/hubServerConfigSlice";
import { setAddHubServerConfig } from "../store/addHubServerConfigSlice";
import HubServerInfoService from "../api/hubServerInfo";
import CustomTextField from "../common/CustomTextField";
const initDashboard = {
  hubServerInfoId: "",
  name: "",
  description: "",
  status: "active",
};

// async function handleSave({ name, description, status, hubServerInfoId, order_by, apiKey }) {
//     let saved = false;
//     await HubServerInfoService.SetHUBServerInfo(name, description, hubServerInfoId, status, order_by, apiKey).then(async (json) => {
//         if (json.success === true && json.count > 0) {
//             saved = true;
//         }
//     });
//     return saved;
// }

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "HUB Server Config", href: "hubServerInfoApi" },
  { id: 2, name: "Add/Edit HUB Server Config", href: "addEditHubServerConfig" },
];
function AddHubServerConfig() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const addHubServerConfig = useSelector(
      ({ addHubServerConfig }) => addHubServerConfig
    );
    const { t } = useTranslation();
    const theme = useTheme();
    const [name, setName] = useState("");
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const organziation = useSelector(({ organziation }) => organziation);

  useEffect(() => {
    setName(addHubServerConfig?.name);
    setDescription(addHubServerConfig?.description);
    setStatus(addHubServerConfig?.status);
    return () => {
      dispatch(setAddHubServerConfig(initDashboard));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addHubServerConfig]);
  async function onSaveClick() {
    const hubServerConfigToSave = {
      name,
      description,
      status,
      hubServerInfoId: addHubServerConfig?.hubServerInfoId,
      apiKey: organziation?.api_key_list?.[0].key,
    };
    handleSave(hubServerConfigToSave);
  }
  function handleSave({ name, description, status, hubServerInfoId, apiKey }) {
    HubServerInfoService.SetHUBServerInfo(
      hubServerInfoId,
      name,
      description,
      status,
      apiKey
    ).then(async (json) => {
      if (json.success === true && json.count > 0) {
        navigate('/hubServerInfoApi');
      }
    });
  }
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{ height: "100%" }}
    >
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
        <Typography
          sx={{
            fontSize: "45px",
            mb: 0,
            color: theme.palette.custom.borderColor,
          }}
          gutterBottom
        >
          {t("Add/Edit HUB Server Config")}:
        </Typography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          width: "100%",
          flexDirection: "row",
          color: "#FFFFFF",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "stretch",
            width: "100%",
            gap: "3rem",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              width: "100%",
            }}
          >
            <CustomTextField
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              id="txtName"
              label={t("Name")}
              variant="filled"
              margin="normal"
            />
            <CustomTextField
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              id="txtStatus"
              label={t("Status")}
              variant="filled"
              margin="normal"
            />
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              width: "100%",
            }}
          >
            <CustomTextField
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              id="Description"
              label={t("Description")}
              variant="filled"
              margin="normal"
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.custom.borderColor,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: "none",
            },
          }}
          onClick={onSaveClick}
        >
          {t("Save")}
        </Button>
      </Box>
    </Box>
  );
}

export default AddHubServerConfig;
