import React from "react";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import SensorsIcon from '@mui/icons-material/Sensors';
import PropTypes from "prop-types";

const StyledButton = styled((props) => <Button  {...props} />)(
    ({ theme }) => ({
        width: '100px',
        height: '32px',
        margin: '0px',
        color: theme.palette.custom.buttonTextColor,
        backgroundColor: theme.palette.custom.buttonColor,
        borderColor: theme.palette.custom.borderColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        '&:hover': {
            backgroundColor: theme.palette.custom.buttonHoverColor,
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: 'none',
        },
    }),
);
function ViewSignalsButton({ onClick }) {
    const { t } = useTranslation();
    return (
        <StyledButton
            startIcon={<SensorsIcon />}
            id="demo-zoom-button"
            variant="contained"
            disableElevation
            onClick={onClick}
        >
            {t('View')}
        </StyledButton>
    );
}
ViewSignalsButton.propTypes = {
    onClick: PropTypes.func,
};

export default ViewSignalsButton;
