import React, {useEffect, useState} from "react";
import {Box, Typography, TextField, Divider} from "@mui/material";
import Breadcrumbs from "../common/breadcrumbs";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {useSelector} from "react-redux";
import DevicesService from "../api/devices";
import {UserPermissionConstants} from "../utils/permissions";
import utils from "../utils/utils";
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactJson from "@microlink/react-json-view";

const breadcrumbs = [
    {id: 0, name: "Home", href: "./"},
    {id: 1, name: "My Devices", href: "myDevices"},
    {id: 2, name: "Device Config", href: "deviceConfig"}
];

const filterJson = (data, query) => {
    // console.log("Original Data:", data);
    // console.log("Search Query:", query);
    if (!query || query.length < 2) return data;
    const search = new RegExp(query.trim(), "i");
    const filterRecursive = (item) => {
        if (typeof item === "string" || typeof item === "number") {
            return search.test(String(item).trim());
        }
        if (Array.isArray(item)) {
            return item.some(filterRecursive);
        }
        if (item && typeof item === "object") {
            const partialKeyMatch = Object.keys(item).some((key) => search.test(key));
            // eslint-disable-next-line no-unused-vars
            const valueMatch = Object.entries(item).some(([key, value]) => {
                if (typeof value === "string" || typeof value === "number") {
                    return search.test(String(value).trim());
                }
                if (Array.isArray(value)) {
                    return value.some((v) => (typeof v === "string" || typeof v === "number" ? search.test(String(v).trim()) : filterRecursive(v)));
                }
                if (typeof value === "object") {
                    return filterRecursive(value);
                }
                return false;
            });
            return partialKeyMatch || valueMatch;
        }
        return false;
    };
    const filteredData = JSON.parse(JSON.stringify(data));
    if (Array.isArray(filteredData?.devices)) {
        filteredData.devices = filteredData.devices.filter((device) => {
            // console.log("Checking device:", device);
            const deviceMatch = filterRecursive(device);
            if (Array.isArray(device.config)) {
                device.config = device.config.filter((configItem) => {
                    const configMatch = filterRecursive(configItem);
                    // console.log("Config Item Match:", configMatch, configItem);
                    return configMatch;
                });
            }
            const finalDeviceMatch = deviceMatch || (device.config && device.config.length > 0);
            // console.log("Final Device Match:", finalDeviceMatch);
            return finalDeviceMatch;
        });
        filteredData.count = filteredData.devices.length;
    }
    // console.log("Final Filtered Data:", filteredData);
    return filteredData;
};

const DeviceConfig = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [jsonData, setJsonData] = useState({});
    const [filteredJson, setFilteredJson] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const organization = useSelector(({organization}) => organization);
    const deviceConfig = JSON.parse(localStorage.getItem("deviceConfig"));
    let DeviceID = deviceConfig?.deviceID;
    const DeviceName = deviceConfig?.deviceName;
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const permissions = useSelector(({permissions}) => permissions);

    useEffect(() => {
        const apiKey = organization?.api_key_list?.[0]?.key;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (typeof DeviceID === "undefined" || DeviceID === null) DeviceID = "";
        console.log("DeviceID:", DeviceID);
        DevicesService.GetPersonDeviceConfig({
            deviceID: DeviceID,
            apiKey
        }).then((json) => {
            if (json) {
                setJsonData(json);
                setFilteredJson(json);
                setTimeout(() => {
                    localStorage.removeItem("deviceConfig");
                }, 0);
            }
        });
        if (utils.hasPermission(UserPermissionConstants.VIEW_DEVELOPER_PORTAL, permissions)) setEditMode(true);
    }, [organization, DeviceID]);

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchQuery(value);
        if (debounceTimer) clearTimeout(debounceTimer);
        const timer = setTimeout(() => {
            const results = filterJson(jsonData, value);
            setFilteredJson(results);
        }, 20);

        setDebounceTimer(timer);
    };

    useEffect(() => {
        return () => {
            if (debounceTimer) clearTimeout(debounceTimer);
        };
    }, [debounceTimer]);

    const validateJson = (json) => {
        try {
            const validateRecursive = (obj) => {
                if (typeof obj === "object" && obj !== null) {
                    for (const [key, value] of Object.entries(obj)) {
                        if (typeof key !== "string") return false;
                        if (value === null) return false;
                        if (typeof value === "object") {
                            const isValid = validateRecursive(value);
                            if (!isValid) return false;
                        }
                    }
                }
                return true;
            };

            return validateRecursive(json);
        } catch (error) {
            console.error("Validation Error:", error);
            return false;
        }
    };

    return (
        <Box
            sx={{
                p: "12px",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.custom.borderColor}`,
                backgroundColor: theme.palette.background.default
            }}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Typography
                sx={{
                    fontSize: "45px",
                    color: theme.palette.custom.text,
                    mb: 2
                }}
                variant="h2">
                {t("Device")} {t("Config")} {t("For")}: {DeviceName}
            </Typography>
            <Divider sx={{my: "20px", backgroundColor: theme.palette.custom.borderColor}} />
            <Box sx={{padding: "20px"}}>
                <TextField placeholder="Search JSON..." variant="outlined" size="small" value={searchQuery} onChange={handleSearch} sx={{mb: 3, width: "100%", border: `1px solid ${theme.palette.custom.borderColor}`, borderRadius: "10px", backgroundColor: theme.palette.custom.inputBackgroundColor}} />
                <ReactJson
                    src={filteredJson}
                    theme="monokai"
                    name={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                    collapsed={false}
                    quotesOnKeys={false}
                    onEdit={
                        editMode
                            ? (edit) => {
                                  const {updated_src} = edit;

                                  if (validateJson(updated_src)) {
                                      console.log("Edit Validated:", edit);
                                      setFilteredJson(updated_src);
                                      localStorage.setItem("filteredJson", JSON.stringify(updated_src));
                                  } else {
                                      console.error("Validation Failed on Edit:", edit);
                                      alert("Invalid edit detected. Please correct your changes.");
                                  }
                              }
                            : null
                    }
                    onAdd={
                        editMode
                            ? (add) => {
                                  const {updated_src} = add;

                                  if (validateJson(updated_src)) {
                                      console.log("Addition Validated:", add);
                                      setFilteredJson(updated_src);
                                      localStorage.setItem("filteredJson", JSON.stringify(updated_src));
                                  } else {
                                      console.error("Validation Failed on Addition:", add);
                                      alert("Invalid addition detected. Please correct your changes.");
                                  }
                              }
                            : null
                    }
                    onDelete={
                        editMode
                            ? (del) => {
                                  const {updated_src} = del;

                                  if (validateJson(updated_src)) {
                                      console.log("Deletion Validated:", del);
                                      setFilteredJson(updated_src);
                                      localStorage.setItem("filteredJson", JSON.stringify(updated_src));
                                  } else {
                                      console.error("Validation Failed on Deletion:", del);
                                      alert("Unexpected validation failure after deletion.");
                                  }
                              }
                            : null
                    }
                    style={{
                        padding: "12px",
                        border: `1px solid ${theme.palette.custom.borderColor}`,
                        borderRadius: "10px",
                        backgroundColor: theme.palette.custom.inputBackgroundColor,
                        color: theme.palette.custom.textLightColor
                    }}
                />
            </Box>
        </Box>
    );
};

export default DeviceConfig;
