import React, { useEffect } from "react";
import { Box, Divider, Typography, capitalize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import UsersIconButton from "../common/usersIconButton";
import DevicesIconButton from "../common/devicesIconButton";
import ContactUsIconButton from "../common/contactUsIconButton";
import utils from "../utils/utils";
import { UserPermissionConstants } from "../utils/permissions";
import {
  GetDateTimeStringToday,
  GetDateTimeString7DaysAgo,
  GetMachineTypeIcon,
  GetBatteryImage,
} from "../utils/systemInfo";
import DevicesService from "../api/devices";
import handleNavigate from "../common/customFunctions/handleNavigate";

// const StyledNumbersText = styled((props) => <Typography {...props} />)(
//   ({ theme }) => ({
//     fontSize: { xs: "2rem", sm: "2.5rem" },
//     color: theme.palette.custom.text,
//     fontWeight: "bold",
//   })
// );
const StyledText = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    fontSize: { xs: "1rem", sm: "1.5rem" },
    color: theme.palette.custom.text,
    marginTop: "2%",
  })
);
const NumberTypography = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.borderColor,
    m: 0,
  })
);

function HomeJojoPage() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organziation = useSelector(({ organziation }) => organziation);
  const permissions = useSelector(({ permissions }) => permissions);

  useEffect(() => {
    if (organziation) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      DevicesService.getDevices({
        DeviceID: "",
        DeviceType: [],
        Source: "iot",
        Live: true,
        apiKey,
        HistoryFromDST: GetDateTimeString7DaysAgo(),
        HistoryToDST: GetDateTimeStringToday(),
      })
        .then((json) => {
          if (json && json.device_profile && json.device_profile.length > 0) {
            // let RecordOPSUM = {
            //   DataCached: false,
            //   lowDeviceCount: 0,
            //   lowSignalCount: 0,
            //   volumeAVG: 0,
            //   moistureLevel: 0,
            //   totalMoist: 0,
            //   totalVol: 0,
            //   totalVolDev: 0,
            //   LeakCount: 0,
            //   totalFlow: 0,
            //   totalFlowDev: 0,
            //   flowAVG: 0,
            //   totalLeakDev: 0,
            //   activeDevices: 0,
            //   totalDevices: 0,
            // };
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleClickButton(event, name) {
    switch (name) {
      case "devices":
        if (
          utils.hasPermission(UserPermissionConstants.VIEW_DEVICES, permissions)
        )
          handleNavigate(event, navigate, "devices?DeviceTitleDataName=Device");
        // navigate("devices?DeviceTitleDataName=Device");
        break;
      case "users":
        if (
          utils.hasPermission(UserPermissionConstants.VIEW_USERS, permissions)
        )
          handleNavigate(event, navigate, "/users");
        // navigate("/users");
        break;
      case "contactUs":
        handleNavigate(event, navigate, "/contactus");
        // navigate("/contactus");
        break;
      default:
        break;
    }
  }
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          <Typography
            sx={{
              fontSize: "45px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            variant="h2"
            gutterBottom
          >
            {organziation.short_name
              ? capitalize(organziation.short_name)
              : null}
          </Typography>
          <Typography
            sx={{
              ml: { xs: 0, sm: 1 },
              mt: { xs: 0, sm: 1 },
              fontSize: { xs: "1rem", sm: "1.5rem" },
              color: theme.palette.custom.text,
            }}
            variant="h4"
            gutterBottom
          >
            {t("ForWaterForLife")}
          </Typography>
        </Box>
        <Typography
          sx={{
            ml: { xs: 0, sm: 1 },
            mt: { xs: 0, sm: 1 },
            fontSize: { xs: "1rem", sm: "1.5rem" },
            color: theme.palette.custom.text,
          }}
          variant="h4"
          gutterBottom
        >
          {t("Dashboard")}
        </Typography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        component="main"
        sx={{
          p: "12px",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "16px",
          border: `1px solid ${theme.palette.custom.borderColor}`,
          height: { xs: "fit-content", sm: "48px" },
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            {t("DataSummary").toLocaleUpperCase()} &nbsp;|&nbsp;
          </Typography>
          <NumberTypography variant="subtitle2">
            {t("Last 7 days")}
            {` (${moment(GetDateTimeString7DaysAgo()).format(
              "yyyy/MM/DD"
            )} - ${moment(GetDateTimeStringToday()).format("yyyy/MM/DD")})`}
          </NumberTypography>
          <Typography
            sx={{ color: theme.palette.custom.text }}
            variant="subtitle2"
            gutterBottom
          >
            &nbsp;|&nbsp;{t("Timespan")}:{t("168 hours")}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          alignItems: { xs: "block", sm: "center" },
          height: "fit-content",
          mt: "30px",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "30%" },
            textAlign: "center",
            borderRadius: "16px",
            padding: "10px",

            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
        >
          <div
            style={{
              width: "20%",
              marginLeft: "40%",
            }}
            dangerouslySetInnerHTML={{
              __html: GetMachineTypeIcon("node").replace(
                "theme.palette.custom.text",
                theme.palette.custom.text
              ),
            }}
          />
          {/* <StyledNumbersText variant="h4">
            {organizationsCount}
          </StyledNumbersText> */}
          <StyledText variant="h6">{t("ActiveDevices")}</StyledText>
          <Divider
            sx={{
              my: "10px",
              backgroundColor: theme.palette.custom.borderColor,
            }}
          />
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "30%" },
            textAlign: "center",
            borderRadius: "16px",
            padding: "10px",

            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
        >
          <div
            style={{
              width: "20%",
              marginLeft: "40%",
            }}
            dangerouslySetInnerHTML={{
              __html: GetBatteryImage(0.6)
                .replace("theme.palette.custom.text", theme.palette.custom.text)
                .replace("theme.palette.custom.borderColor", "red"),
            }}
          />
          {/* <StyledNumbersText variant="h4">
            {organizationsCount}
          </StyledNumbersText> */}
          <StyledText variant="h6">{t("DevicesWithLowBattery")}</StyledText>
          <Divider
            sx={{
              my: "10px",
              backgroundColor: theme.palette.custom.borderColor,
            }}
          />
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "30%" },
            textAlign: "center",
            borderRadius: "16px",
            padding: "10px",

            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
        >
          <img
            style={{ width: "40px", marginRight: "4px" }}
            src={`${process.env.PUBLIC_URL}/images/icons/signalbad_${localStorage.getItem("color_theme") === "dark" ? "Dark" : "Light"
              }.png`}
            alt="logo"
          />

          {/* <StyledNumbersText variant="h4">
            {organizationsCount}
          </StyledNumbersText> */}
          <StyledText variant="h6">{t("DevicesWithBadSignal")}</StyledText>
          <Divider
            sx={{
              my: "10px",
              backgroundColor: theme.palette.custom.borderColor,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          alignItems: { xs: "block", sm: "center" },
          mt: "30px",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "49%" },
            textAlign: "center",
            borderRadius: "16px",
            padding: "10px",

            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
        >
          <StyledText variant="h6">{t("RadarDashboardSummary")}</StyledText>
          {/* <StyledNumbersText variant="h4">
            {organizationsCount}
          </StyledNumbersText> */}
          <img
            style={{ width: "150px", marginRight: "4px" }}
            src={`${process.env.PUBLIC_URL}/images/deviceradar.svg`}
            alt="logo"
          />
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "49%" },
            textAlign: "center",
            borderRadius: "16px",
            padding: "10px",
            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
        >
          <StyledText variant="h6">{t("RopeDashboardSummary")}</StyledText>
          {/* <StyledNumbersText variant="h4">
            {organizationsCount}
          </StyledNumbersText> */}
          <img
            style={{ width: "100px", marginRight: "4px" }}
            src={`${process.env.PUBLIC_URL}/images/devicerope.svg`}
            alt="logo"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          alignItems: { xs: "block", sm: "center" },
          height: "fit-content",
          mt: "30px",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "49%" },
            textAlign: "center",
            borderRadius: "16px",
            padding: "10px",

            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
        >
          <StyledText variant="h6">{t("FlowDashboardSummary")}</StyledText>
          {/* <StyledNumbersText variant="h4">
            {organizationsCount}
          </StyledNumbersText> */}
          <img
            style={{ width: "150px", marginRight: "4px" }}
            src={`${process.env.PUBLIC_URL}/images/deviceflow.svg`}
            alt="logo"
          />
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "49%" },
            textAlign: "center",
            borderRadius: "16px",
            padding: "10px",

            border: `1px solid ${theme.palette.custom.borderColor}`,
          }}
        >
          <StyledText variant="h6">{t("FloodDashboardSummary")}</StyledText>
          {/* <StyledNumbersText variant="h4">
            {organizationsCount}
          </StyledNumbersText> */}
          <img
            style={{ width: "150px", marginRight: "4px" }}
            src={`${process.env.PUBLIC_URL}/images/deviceflood.svg`}
            alt="logo"
          />
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        sx={{
          textAlign: "center",
          mt: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "2rem", sm: "2.5rem" },
            color: theme.palette.custom.text,
          }}
          variant="h2"
          gutterBottom
        >
          {t("WhereWouldYouLikeToGo")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          alignItems: { xs: "block", sm: "center" },
          height: "fit-content",
        }}
      >
        <DevicesIconButton
          disabled={
            !utils.hasPermission(
              UserPermissionConstants.VIEW_DEVICES,
              permissions
            )
          }
          onClick={(e) => handleClickButton(e, "devices")}
        />
        <UsersIconButton
          disabled={
            !utils.hasPermission(
              UserPermissionConstants.VIEW_USERS,
              permissions
            )
          }
          onClick={(e) => handleClickButton(e, "users")}
        />
        <ContactUsIconButton onClick={(e) => handleClickButton(e, "contactUs")} />
      </Box>
    </Box>
  );
}

export default HomeJojoPage;
