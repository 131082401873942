import React from "react";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import PropTypes from "prop-types";




// const listArray = [
//     {
//         label: "North American Equities (Pen)",
//         amount: 40,
//         index: 1,
//         color: "#FF98BD",
//     },
//     {
//         label: "Specialist (Pen)",
//         amount: 25,
//         index: 2,
//         color: "#C2AAF3",
//     },
//     {
//         label: "UK All Companies (Pen)",
//         amount: 15,
//         index: 3,
//         color: "#FFC998",
//     },
//     {
//         label: "Japan Equities (Pen)",
//         amount: 13,
//         index: 4,
//         color: "#98DAFF",
//     },
// ];
const amount = (d) => d.amount;

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export default function DonutChart({
  width,
  height,
  listArray,
  margin = defaultMargin
}) {
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
    // } = useTooltip<TooltipData>();
  } = useTooltip();
  const { t } = useTranslation();
  const theme = useTheme();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true
  });

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const top = centerY + margin.top;
  const left = centerX + margin.left;
  const pieSortValues = (a, b) => b - a;

  const getLabelAmoutColor = scaleOrdinal({
    domain: listArray.map((l) => l.label),
    range: listArray.map((l) => l.color),
  });
  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: "#2B2F38",
    color: theme.palette.custom.text,
    maxWidth: 220,
    border: `1px solid ${theme.palette.custom.borderColor}`,
  };

  const handleArrowIcon = (newValue, OldValue, reverse, fontSize) => {
    if (newValue.toFixed(2) > OldValue.toFixed(2)) {
      return reverse ? (
        <ArrowCircleDownRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleUpRoundedIcon
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else if (newValue.toFixed(2) < OldValue.toFixed(2)) {
      return reverse ? (
        <ArrowCircleUpRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleDownRoundedIcon
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else {
      return (
        <RemoveCircleOutlineRoundedIcon
          sx={{
            minHeight: 48,
            color: "grey",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    }
  };

  const handleCalculatePercentage = (amount) => {
    let total = 0

    if (listArray) {
      for (let index = 0; index < listArray.length; index++) {

        total = total + Number(listArray[index].amount)
      }

    }
    if (total > 0) {
      return (amount / total) * 100
    } else {
      return 0
    }
  };
  return (
    <>
      <svg ref={containerRef} width={width} height={height}>
        {listArray && <Group top={top} left={left}>
          <Pie
            data={listArray}
            pieValue={amount}
            pieSortValues={pieSortValues}
            outerRadius={radius}
            innerRadius={radius * 0.6}
          >
            {(pie) => {
              return pie.arcs.map((arc, index) => {
                const { label } = arc.data;
                const [centroidX, centroidY] = pie.path.centroid(arc);
                const arcPath = pie.path(arc);
                const arcFill = getLabelAmoutColor(label);
                return (
                  <g
                    key={`arc-${label}-${index}`}
                    onMouseEnter={() => {
                      showTooltip({
                        tooltipData: arc.data,
                        tooltipLeft: centroidX,
                        tooltipTop: centroidY
                      });
                    }}
                    onMouseLeave={hideTooltip}
                  >
                    <path d={arcPath} fill={arcFill} />
                  </g>
                );
              });
            }}
          </Pie>
        </Group>}
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop + height / 2}
          left={tooltipLeft + width / 2}
          style={tooltipStyles}
        >
          <strong><span style={{ color: theme.palette.custom.borderColor, display: "block", marginBottom: '6px' }}>
            {" "}
            {t(tooltipData.label)}{" "}
          </span>
            <span style={{ display: "block", color: theme.palette.custom.text, marginBottom: '4px' }}>
              {`Time(Hours): ${tooltipData.amount}`}
            </span>
            <span style={{ display: "block", color: theme.palette.custom.text, marginBottom: '4px' }}>
              {`${handleCalculatePercentage(tooltipData.amount).toFixed(2)}%`}
            </span></strong>
          <span style={{ display: "block", color: theme.palette.custom.text }}>
            {handleArrowIcon(tooltipData.current, tooltipData.previous, false, 24)}
          </span>
        </TooltipInPortal>
      )}
    </>
  );
}
DonutChart.propTypes = {
  width: PropTypes.string,
  height: PropTypes.number,
  listArray: PropTypes.array,
  margin: PropTypes.string,
};
