import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Breadcrumbs from '../common/breadcrumbs';
import { useNavigate } from "react-router-dom";
import { setDashboard } from "../store/dashboardSlice";
import DashboardsService from "../api/dashboards";
import CustomTextField from "../common/CustomTextField";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

const initDashboard = {
    grafana_dashboard_id: "",
    name: "",
    name_tab: "",
    status: "",
    name_backend: "",
    name_type: "",
    description: "",
}
// const initDashboard = {  
//     grafana_dashboard_id: "20221109095028816_3sQLu61VbmX2pPDCG",
//     name: "Device Status Comparison",
//     name_backend: "DeviceStatusComparison",
//     name_tab: "All Devices",
//     name_type: "Device",
//     description: "Device Status Comparison",
//     status: "active"
// }

async function handleSave({ grafana_dashboard_id, name, name_backend, description, name_type, name_tab, status, api_key_current }) {
    let saved = false;
    await DashboardsService.SetGrafanaDashboard(grafana_dashboard_id, name, name_backend, name_tab, name_type, description, status, api_key_current).then(async (json) => {
        if (json.success === true && json.count > 0) {
            saved = true;
        }
    });
    return saved;
}

const breadcrumbs = [
    { id: 0, name: 'Home', href: './' },
    { id: 1, name: 'Dashboards', href: 'dashboards' },
    { id: 2, name: 'Add/Edit Dashboard', href: 'grafanaBackendDashboardAddEdit' },
];
function AddEditDashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dashboard = useSelector(({ dashboard }) => dashboard);
    const organziation = useSelector(({ organziation }) => organziation);
    const { t } = useTranslation();
    const theme = useTheme();
    const [name, setName] = useState("");
    const [nameTab, setNameTab] = useState("");
    const [status, setStatus] = useState("");
    const [nameBackend, setNameBackend] = useState("");
    const [nameType, setNameType] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        setName(dashboard.name || "");
        setNameTab(dashboard.name_tab || "");
        setStatus(dashboard.status || "");
        setNameBackend(dashboard.name_backend || "");
        setNameType(dashboard.name_type || "");
        setDescription(dashboard.description || "");
        return () => {
            dispatch(setDashboard(initDashboard));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard]);
    function canBeSubmitted() {
        return name
    }
    async function onSaveClick() {
        const dashboardToSave = {
            grafana_dashboard_id: dashboard.grafana_dashboard_id,
            name,
            name_backend: nameBackend,
            description,
            name_type: nameType,
            name_tab: nameTab,
            status,
            api_key_current: organziation?.api_key_list?.[0]?.key
        }
        const saved = await handleSave(dashboardToSave);
        if (saved) {
            navigate('/grafanaBackend');
        }
    }
    return (
        <Box
            sx={{ p: "12px", borderRadius: "16px", border: `1px solid ${theme.palette.custom.borderColor}` }}
            style={{ height: "100%" }}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Box sx={{ display: { xs: "block", sm: "flex" } }}>
                <Typography sx={{ fontSize: "45px", mb: 0, color: theme.palette.custom.borderColor }} gutterBottom>
                    {t("Add/Edit Grafana Dashboard")}:
                </Typography>
            </Box>
            <Divider sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }} />
            <Box sx={{ display: { xs: "block", sm: "flex" }, width: "100%", flexDirection: "row", color: "#FFFFFF" }}>
                <Box sx={{ display: "flex", justifyContent: "stretch", width: "100%", gap: "3rem" }}>
                    <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "column", width: "100%" }}>
                        <CustomTextField
                            error={!name}
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                            id="txtName"
                            label={t("Name")}
                            variant="filled"
                            margin="normal"
                        />
                        <CustomTextField
                            value={nameTab}
                            onChange={(e) => {
                                setNameTab(e.target.value);
                            }}
                            id="txtNameTab"
                            label={t("Name Tab")}
                            variant="filled"
                            margin="normal"
                            placeholder={t("Name Tab Placeholder")}
                        />
                        <CustomTextField
                            value={status}
                            onChange={(e) => {
                                setStatus(e.target.value);
                            }}
                            id="txtStatus"
                            label={t("Status")}
                            variant="filled"
                            margin="normal"
                        />
                    </Box>
                    <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "column", width: "100%" }}>
                        <CustomTextField
                            value={nameBackend}
                            onChange={(e) => {
                                setNameBackend(e.target.value);
                            }}
                            id="txtNameBackend"
                            label={t("Name Backend")}
                            variant="filled"
                            margin="normal"
                        />
                        <CustomTextField
                            value={nameType}
                            onChange={(e) => {
                                setNameType(e.target.value);
                            }}
                            id="txtNameType"
                            label={t("Name Type")}
                            variant="filled"
                            margin="normal"
                            placeholder={t("Name Type Placeholder")}
                        />
                        <CustomTextField
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                            id="txtDescription"
                            label={t("Description")}
                            variant="filled"
                            margin="normal"
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: { xs: "block", sm: "flex" }, flexDirection: "row", justifyContent: "flex-end", width: "100%", alignItems: "center" }}>
                <Button
                    variant="contained"
                    sx={{
                        "backgroundColor": theme.palette.custom.borderColor,
                        color: "white",
                        '&:hover': {
                            backgroundColor: theme.palette.custom.buttonHoverColor,
                            color: theme.palette.custom.buttonHoverTextColor,
                            boxShadow: 'none',
                        }
                    }}
                    disabled={!canBeSubmitted()}
                    onClick={onSaveClick}>
                    {t("Save")}
                </Button>
            </Box>
        </Box>
    );
}

export default AddEditDashboard;
