import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme, styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import InputOptionsList from "../common/inputOptionsList";
import CustomTextField from "../common/CustomTextField";


const StyledBoxButton = styled((props) => <Box {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.custom.buttonColor,
  borderRadius: "4px",
  border: `1px solid ${theme.palette.custom.borderColor}`,
  // display: "flex",
  // justifyContent: "center",
  width: "46px",
  height: "32px",
  padding: "5px 12px",
  marginRight: "5px",
}));
const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  width: "150px",
  height: "32px",
  margin: "16px 0px 0px 0px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));
export default function RequirementInput({
  input,
  index,
  groupIndex,
  handleChangeInput,
  handleDeleteInput,
  handleSaveInput,
  handleAddInputOption,
  handleChangeInputOption,
  handleSaveInputOption,
  handleDeleteInputOption,
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleMoveInput = () => { };
  const handleDelete = () => {
    handleDeleteInput(groupIndex, index);
  };
  function handleChange(value, name) {
    handleChangeInput(groupIndex, index, value, name);
  }
  function handleChangeOption(value, optionIndex) {
    handleChangeInputOption(groupIndex, index, value, optionIndex);
  }
  function handleSave(value, name) {
    handleSaveInput(groupIndex, index, value, name);
  }
  function handleOptiontSave(value, optionIndex) {
    handleSaveInputOption(groupIndex, index, value, optionIndex);
  }
  function handleAddOption() {
    handleAddInputOption(groupIndex, index);
  }
  function handleDeleteOption(optionIndex) {
    handleDeleteInputOption(groupIndex, index, optionIndex);
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: { xs: "block", sm: "center" },
        marginBottom: "12px",
      }}
    >
      <Box sx={{ display: "flex", width: { xs: "75%", sm: "100^%" } }}>
        <CustomTextField
          id="txtUser"
          label={t("inputName")}
          variant="filled"
          value={input.name}
          onChange={(event) => handleChange(event.target.value, "name")}
          onBlur={(event) => handleSave(event.target.value, "name")}
        />
        <Box
          sx={{
            width: { xs: "100%", sm: "20%" },
            margin: "0px 16px 0px 0px",
            alignSelf: "baseline",
          }}
        >
          <InputOptionsList
            value={input.input_requirement_config_uid}
            onBlur={(event) => handleSave(event.target.value, "name")}
            handleChooseOption={handleChange}
            optionId={input.input_requirement_config_uid}
          />

          {(input.input_requirement_config_uid === 5 ||
            input.input_requirement_config_uid === 6) &&
            input.select &&
            input.select.map((option, index) => (
              <Box key={option.name} sx={{ display: "flex" }}>
                <CustomTextField
                  key={option.name}
                  sx={{ margin: "16px 0px 0px 0px" }}
                  id="txtUser"
                  label={t("Option")}
                  variant="filled"
                  value={option.name}
                  onChange={(event) =>
                    handleChangeOption(event.target.value, index)
                  }
                  onBlur={(event) =>
                    handleOptiontSave(event.target.value, index)
                  }
                />
                <StyledBoxButton
                  onClick={() => handleDeleteOption(index)}
                  sx={{
                    margin: "16px 0px 0px 4px",
                    height: "36px",
                    padding: "6px",
                  }}
                >
                  <DeleteIcon
                    // onClick={handleDelete}
                    sx={{
                      color: theme.palette.custom.buttonTextColor,
                      fontSize: 22,
                    }}
                  />
                </StyledBoxButton>
              </Box>
            ))}
          {input.select?.length && (
            <StyledButton variant="contained" onClick={handleAddOption}>
              {t("Option")}
            </StyledButton>
          )}
        </Box>

        <CustomTextField
          id="txtUser"
          label={t("Description")}
          variant="filled"
          value={input.description}
          onChange={(event) => handleChange(event.target.value, "description")}
          onBlur={(event) => handleSave(event.target.value, "name")}
        />
        <CustomTextField
          id="txtUser"
          label={t("sortOrder")}
          variant="filled"
          value={input.order_by}
          onChange={(event) => handleChange(event.target.value, "sortOrder")}
          onBlur={(event) => handleSave(event.target.value, "name")}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        <StyledBoxButton onClick={handleDelete}>
          <DeleteIcon
            // onClick={handleDelete}
            sx={{
              color: theme.palette.custom.buttonTextColor,
              fontSize: 20,
            }}
          />
        </StyledBoxButton>
        <StyledBoxButton onClick={handleMoveInput}>
          <OpenWithIcon
            //  onClick={handleMoveInput}
            sx={{
              color: theme.palette.custom.buttonTextColor,
              fontSize: 20,
            }}
          />
        </StyledBoxButton>
      </Box>
    </Box>
  );
}
RequirementInput.propTypes = {
  input: PropTypes.object.isRequired,
  index: PropTypes.number,
  groupIndex: PropTypes.number,
  handleChangeInput: PropTypes.func,
  handleDeleteInput: PropTypes.func,
  handleSaveInput: PropTypes.func,
  handleAddInputOption: PropTypes.func,
  handleChangeInputOption: PropTypes.func,
  handleSaveInputOption: PropTypes.func,
  handleDeleteInputOption: PropTypes.func,
};
