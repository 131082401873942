import React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

export default function NumberTypography({ children }) {
    const theme = useTheme();
    return (
        <Typography sx={{
            color: theme.palette.custom.borderColor,
            m: 0
        }}>
            {children}
        </Typography>
    );
}
NumberTypography.propTypes = {
    children: PropTypes.string,
};