import React, { Fragment } from "react";
import { Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import EnhancedTableHead from "../common/EnhancedTableHead";
import StyledTypography from "../common/StyledTypography";
import TableContainer from "@mui/material/TableContainer";
import { useTranslation } from "react-i18next";
import TablePagination from "@mui/material/TablePagination";
import Breadcrumbs from "../common/breadcrumbs";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Documentation", href: "documentation" },
];

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);

const headCells = [
  {
    id: "sKeys",
    numeric: false,
    disablePadding: true,
    label: "Shortcut Keys",
    sort: true,
  },
  {
    id: "desc",
    numeric: false,
    disablePadding: false,
    label: "Description",
    sort: true,
  },
];

function Documentation() {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          <Box sx={{ width: "100%" }}>
            {
              //<HomePagesMenu pageName={'Maintenance'} />
            }
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Typography
              sx={{
                fontSize: "45px",
                mb: 0,
                color: theme.palette.custom.borderColor,
              }}
              variant="h2"
              gutterBottom
            >
              {
                // {capitalize(t('Devices'))}
              }
              {t("Documentation")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box sx={{ width: "100%" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          //  size={'medium'}
          >
            <EnhancedTableHead headCells={headCells} hideCheck />

            <TableBody>
              <Fragment>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell align="left">
                    <StyledTypography variant="subtitle2">
                      {"Ctrl + Z"}
                    </StyledTypography>
                  </TableCell>
                  <TableCell align="left">
                    <StyledTypography variant="subtitle2">
                      {"Zoom out time range"}
                    </StyledTypography>
                  </TableCell>
                </TableRow>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell align="left">
                    <StyledTypography variant="subtitle2">
                      {"Ctrl + ←"}
                    </StyledTypography>
                  </TableCell>
                  <TableCell align="left">
                    <StyledTypography variant="subtitle2">
                      {"Move time range back"}
                    </StyledTypography>
                  </TableCell>
                </TableRow>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell align="left">
                    <StyledTypography variant="subtitle2">
                      {"Ctrl + →"}
                    </StyledTypography>
                  </TableCell>
                  <TableCell align="left">
                    <StyledTypography variant="subtitle2">
                      {"Move time range forward"}
                    </StyledTypography>
                  </TableCell>
                </TableRow>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell align="left">
                    <StyledTypography variant="subtitle2">
                      {"Ctrl + Left Click (On Graph Traces)"}
                    </StyledTypography>
                  </TableCell>
                  <TableCell align="left">
                    <StyledTypography variant="subtitle2">
                      {"Show/Hide Graph Traces"}
                    </StyledTypography>
                  </TableCell>
                </TableRow>
              </Fragment>
            </TableBody>
          </Table>
        </TableContainer>
        <StyledTablePagination
          SelectProps={{
            inputProps: {
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.custom.text,
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: theme.palette.custom.borderColor,
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "blue",
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                      backgroundColor: "blue",
                    },
                  },
                },
              },
            },
          }}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={4}
          rowsPerPage={5}
          page={0}
        />
      </Box>
    </Box>
  );
}

export default Documentation;
