import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Home from "./home";
import HomeGrafanaPage from "./homeGrafanaPage";
import HomeSimacPage from "./homeSimacPage";
import HomeJojoPage from "./homeJojoPage";
import { UpdateURLQueryStringWithoutReload } from "../utils/systemInfo";

function IndexPage() {
  const [choosePage, setChoosePage] = useState(null);
  const organziation = useSelector(({ organziation }) => organziation);
  useEffect(() => {
    if (organziation) {
      switch (organziation.full_name.toLowerCase()) {
        case "tsebo":
          UpdateURLQueryStringWithoutReload("Dashboard", "TseboHomeDashboard");
          UpdateURLQueryStringWithoutReload("DeviceTitleDataName", "Device");
          setChoosePage(2);
          break;
        case "nerospec group":
          setChoosePage(3);
          break;
        case "jojo":
        case "maximator":
        case "motion hub":
        case "nerospec iot":
        case "checkers":
        case "behrtech":
        case "premisehq saas inc.":
        case "soteria risk solutions":
        case "connected sensors":
        case "ctrack":
          setChoosePage(4);
          break;
        default:
          setChoosePage(1);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organziation]);
  return (
    <>
      {choosePage === 1 && <Home />}
      {choosePage === 2 && <HomeGrafanaPage />}
      {choosePage === 3 && <HomeSimacPage />}
      {choosePage === 4 && <HomeJojoPage />}
    </>
  );
}

export default IndexPage;
