import React, { useEffect, useState, Fragment } from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import OrganizationService from "../api/organization";
import AssignButton from "../common/assignButton";
import FakeUser from "../FakeUser.png";
import StyledTypography from "../common/StyledTypography";
import ViewButton from "../common/viewButton";
import { getCookie } from "../utils/cookies";
import { LatestDataColoring } from "../utils/systemInfo";
import { showMessage } from "../store/messageSlice";
import AlertDialog from "../common/AlertDialog";
import handleNavigate from "../common/customFunctions/handleNavigate";


export default function UserTableRow({
  row,
  handleClick,
  isItemSelected,
  labelId,
  userTypes,
  refreshUsers,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const organziation = useSelector(({ organziation }) => organziation);
  const timezoneMins = useSelector(({ timezone }) => timezone);

  const User_SignIn_Logger = row.signinLogger;
  let User_LastSeen =
    User_SignIn_Logger.length > 0 ? User_SignIn_Logger[0].dst : "N/A";
  let User_AppVersion =
    User_SignIn_Logger.length > 0
      ? User_SignIn_Logger[0].app_version
      : getCookie("api_app_version");
  let User_Language =
    User_SignIn_Logger.length > 0
      ? User_SignIn_Logger[0].device_system_language
      : localStorage.getItem("i18nextLng");
  useEffect(() => {
    setEmail("");
    setMobile("");
    if (row.contact.length > 0) {
      for (let i in row.contact) {
        if (
          row.contact[i].contact_type === "email_address" &&
          row.contact[i].primary_contact === true
        ) {
          setEmail(row.contact[i].value);
        }
        if (
          row.contact[i].contact_type === "tel_mobile" &&
          row.contact[i].primary_contact === true
        ) {
          setMobile(row.contact[i].value);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.contact]);

  function handleOpenCollapse() {
    // if (!open) {
    //   const apiKey = organziation?.api_key_list?.[0]?.key;
    // }
    setOpen(!open);
  }
  function getUserType(id) {
    if (!id) {
      return;
    }
    const usertype = userTypes.find((x) => x.value === id);
    return usertype.label;
  }
  function clickOnEmail(emailValue) {
    window.location = `mailto:${emailValue}`;
  }
  function handleNavigateUser(event) {
    handleNavigate(event, navigate, `/profile?id=${row.profile_id}&type=${row.user_type_uid}`);
    // navigate();
  }
  function handleOpenDialog() {
    // let assignToOrg = JSON.parse(localStorage.getItem("assignToOrg"));
    // if (row.user_type_uid !== 0 && organziation) {
    //   OrganizationService.SetOperationPersonLink(
    //     {
    //       operation_id: organziation.uid,
    //       person_id: row.profile_id,
    //       user_type_uid: row.user_type_uid,
    //       auto_assign_ticket: "0",
    //       status: IsLink ? "link" : "unlink",
    //     },
    //     assignToOrg.apiKey
    //   ).then((json) => {
    //     if (json) {
    //       // handleChangeUserData(row, IsLink, row.user_type_uid);
    //     }
    //   });
    // } else {
    //   window.alert("Select a user type first.");
    // }
    setOpenDialog(true);
  }
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleAssign = () => {
    setSaveLoading(true);
    const apiKey = organziation?.api_key_list?.[0]?.key;
    if (row.user_type_uid !== 0 && organziation) {
      OrganizationService.SetOperationPersonLink(
        {
          operation_id: organziation.uid,
          person_id: row.profile_id,
          user_type_uid: row.user_type_uid,
          auto_assign_ticket: "0",
          status: "unlink",
        },
        apiKey
      )
        .then((json) => {
          if (json) {
            dispatch(
              showMessage({
                message: t("successfully saved"), //text or html
                autoHideDuration: 10000, //ms
                anchorOrigin: {
                  vertical: "top", //top bottom
                  horizontal: "center", //left center right
                },
                variant: "success", //success error info warning null
              })
            );
            refreshUsers();
            setSaveLoading(false);
            setOpenDialog(false);
            // handleChangeUserData(row, IsLink, row.user_type_uid);
          }
        })
        .catch(() => {
          setSaveLoading(false);
          showErrorMessage();
          setOpenDialog(false);
        });
    }
  };
  function showErrorMessage() {
    dispatch(
      showMessage({
        message: t("error occurred"), //text or html
        autoHideDuration: 10000, //ms
        anchorOrigin: {
          vertical: "top", //top bottom
          horizontal: "center", //left center right
        },
        variant: "error", //success error info warning null
      })
    );
  }
  return (
    <Fragment key={row.firstname}>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.firstname}
        selected={isItemSelected}
        sx={{ cursor: "pointer" }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleOpenCollapse}
            sx={{
              backgroundColor: theme.palette.custom.borderColor,
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.firstname, row.id)}
          sx={{ height: "100%", color: theme.palette.custom.text }}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <Avatar alt={`${row.firstname}`} src={row.public_url || FakeUser} />
            {/* <span
              className="pfpSpan"
              style={{
                width: "40px",
                height: "40px",
                backgroundImage: `url(${row.public_url})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></span> */}
            <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
              {row.firstname}
            </StyledTypography>{" "}
          </Box>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.firstname, row.id)}
          align="left"
        >
          <StyledTypography variant="subtitle2">
            {row.lastname}
          </StyledTypography>
        </TableCell>
        <TableCell onClick={() => clickOnEmail(email)} align="left">
          <StyledTypography
            sx={{ textDecoration: "underline" }}
            variant="subtitle2"
          >
            {email}
          </StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.firstname, row.id)}
          align="left"
        >
          <StyledTypography variant="subtitle2">{mobile}</StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.firstname, row.id)}
          align="left"
        >
          <StyledTypography variant="subtitle2">
            {getUserType(row.user_type_uid)}
          </StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.firstname, row.id)}
          align="left"
        >
          <StyledTypography
            variant="subtitle2"
            style={{ color: LatestDataColoring(row?.signinLogger?.[0]?.dst) }}
          >
            {row.signinLogger !== null && row.signinLogger.length > 0
              ? moment
                .utc(row?.signinLogger?.[0]?.dst)
                .add(timezoneMins, "minutes")
                .format("YYYY-MM-DD HH:mm")
              : ""}
            { }
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <Button className="viewUser" hidden onClick={handleNavigateUser}>
              <ViewButton />
            </Button>
            <Button
              className="viewUser"
              hidden
              onClick={() => handleOpenDialog()}
            >
              <AssignButton
                type={"remove"}
                title={"Unassign " + row.firstname + " " + row.lastname}
              />
            </Button>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow key={row.name}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                padding: "5px",
              }}
            >
              <Typography
                sx={{ color: theme.palette.custom.text }}
                variant="h6"
                gutterBottom
                component="div"
              >
                {`${t("USER DATA FOR")}:`} {row.firstname} {row.lastname}{" "}
                <span style={{ color: "lightgreen" }}>{`${t("SUMMARY")}`}</span>
              </Typography>
              <Box
                sx={{
                  textAlign: "left",
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ color: theme.palette.custom.text, width: "25%" }}
                  variant="h6"
                  gutterBottom
                  component="div"
                >
                  {`${t("Last Login")}:`}{" "}
                  <p style={{ fontWeight: "bold" }}>{User_LastSeen}</p>
                </Typography>
                <Typography
                  sx={{ color: theme.palette.custom.text, width: "25%" }}
                  variant="h6"
                  gutterBottom
                  component="div"
                >
                  {`${t("Organization")}:`}{" "}
                  <p style={{ fontWeight: "bold" }}>{organziation?.full_name}</p>
                </Typography>
                <Typography
                  sx={{ color: theme.palette.custom.text, width: "25%" }}
                  variant="h6"
                  gutterBottom
                  component="div"
                >
                  {`${t("SIMAC Version")}:`}{" "}
                  <p style={{ fontWeight: "bold" }}>{User_AppVersion}</p>
                </Typography>
                <Typography
                  sx={{ color: theme.palette.custom.text, width: "25%" }}
                  variant="h6"
                  gutterBottom
                  component="div"
                >
                  {`${t("Language")}:`}{" "}
                  <p style={{ fontWeight: "bold" }}>{User_Language}</p>
                </Typography>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <AlertDialog
        open={openDialog}
        onClose={handleClose}
        onDoFunction={handleAssign}
        saveLoading={saveLoading}
        dialogTitle={t("Are you sure you want to remove this user?")}
        cancelLabel={t("Cancel")}
        buttonLabel={t("Remove")}
      />
    </Fragment>
  );
}
UserTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  labelId: PropTypes.string,
  isItemSelected: PropTypes.bool,
  userTypes: PropTypes.array,
  refreshUsers: PropTypes.func,
};
