/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setHubServerConfig = createAsyncThunk(
  "hubServerConfig/setHubServerConfig",
  async (inputData) => {
    let hubServerConfigArray = [];
    if (inputData === null) {
      return null;
    }
    hubServerConfigArray = PopulateHubServerConfigArray(inputData.jsonCurrent);
    if (
      inputData.newSearchText &&
      inputData.newSearchText.length > 0 &&
      hubServerConfigArray &&
      hubServerConfigArray.length > 0
    ) {
      const searchText = inputData.newSearchText.toLowerCase();
      return hubServerConfigArray.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText)
        )
      );
    }
    return hubServerConfigArray;
  }
);
function PopulateHubServerConfigArray(jsonThisWeek) {
  let OpsumArray = [];
  for (let i in jsonThisWeek) {
    let opsumDataObj = {
      id: "",
      name: "",
      description: "",
      serverId: "",
      status: "",
    };
    opsumDataObj.id = jsonThisWeek[i].info?.hub_server_info_id;
    opsumDataObj.name = jsonThisWeek[i].info?.name;
    opsumDataObj.description = jsonThisWeek[i].info?.description;
    opsumDataObj.status = jsonThisWeek[i].info?.status;
    opsumDataObj.serverId = jsonThisWeek[i].attribute?.value;

    OpsumArray.push(opsumDataObj);
  }
  return OpsumArray;
}

const initialState = null;

const hubServerConfigSlice = createSlice({
  name: "hubServerConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setHubServerConfig.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const selectHubServerConfig = ({ hubServerConfig }) => hubServerConfig;

export default hubServerConfigSlice.reducer;
