import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TableGreenTypography from "../common/TableGreenTypography";
import StyledTypography from "../common/StyledTypography";
import AssignButton from "../common/assignButton";
import OrganizationService from "../api/organization";
import { LatestDataColoring } from "../utils/systemInfo";
import AlertDialog from "../common/AlertDialog";
import { showMessage } from "../store/messageSlice";
import { useDispatch, useSelector } from "react-redux";

export default function DeviceTableRow({
  row,
  labelId,
  handleChangeData,
  iKey,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const timezoneMins = useSelector(({ timezone }) => timezone);

  function handleAssign(isLink) {
    setSaveLoading(true);
    let org = JSON.parse(localStorage.getItem("assignToOrg"));
    OrganizationService.SetAssignDeviceToOrg({
      operation_uid: org.uid,
      device_id: row.DevicePID,
      status: isLink ? "link" : "unlink",
      apiKey: org.apiKey,
    })
      .then((json) => {
        if (json) {
          handleChangeData(row, isLink);
          dispatch(
            showMessage({
              message: isLink
                ? t("Device/Machine Assigned Successfully")
                : t("Device/Machine unassigned Successfully"), //text or html
              autoHideDuration: 10000, //ms
              anchorOrigin: {
                vertical: "top", //top bottom
                horizontal: "center", //left center right
              },
              variant: "success", //success error info warning null
            })
          );
        }
        setSaveLoading(false);
        setOpenDialog(false);
      })
      .catch(() => {
        setSaveLoading(false);
        setOpenDialog(false);
        dispatch(
          showMessage({
            message: t("error occurred"), //text or html
            autoHideDuration: 10000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "error", //success error info warning null
          })
        );
      });
  }

  function getButton() {
    const status = row.status;
    if (status === "Assigned") {
      return (
        <Button
          className="viewUser"
          hidden
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          <AssignButton type={"remove"} title={"Unassign"} />
        </Button>
      );
    } else {
      return (
        <Button
          className="viewUser"
          hidden
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          <AssignButton type={"add"} title={"Assign"} />
        </Button>
      );
    }
  }

  function handleClose() {
    setOpenDialog(false);
  }
  return (
    <>
      <TableRow hover tabIndex={-1} key={iKey} sx={{ cursor: "pointer" }}>
        <TableCell
          sx={{
            height: "100%",
            color: theme.palette.custom.text,
            padding: "16px",
          }}
          component="th"
          id={labelId}
          scope="row"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
              justifyContent: "left",
            }}
          >
            <StyledTypography variant="subtitle2">
              {row.DeviceName}
            </StyledTypography>{" "}
          </Box>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {row.DeviceTitleDataName === "Device" ||
              isNaN(row.DeviceSerialHex) ||
              !row.DeviceSerial
              ? row.DeviceSerial
              : `${row.DeviceSerialHex} (${row.DeviceSerial})`}
          </StyledTypography>
        </TableCell>
        <TableCell
          sx={{
            height: "100%",
            color: theme.palette.custom.text,
            padding: "16px",
          }}
          component="th"
          id={labelId}
          scope="row"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <div
              style={{ width: "40px" }}
              dangerouslySetInnerHTML={{
                __html: row.DeviceTypeIcon.replace(
                  "theme.palette.custom.text",
                  theme.palette.custom.text
                ),
              }}
            />{" "}
            <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
              {row.DeviceType}
            </StyledTypography>{" "}
          </Box>
        </TableCell>
        <TableCell align="left">
          <StyledTypography
            variant="subtitle2"
            color={row.status === "Assigned" && "green"}
          >
            {row.status}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <TableGreenTypography
            color={LatestDataColoring(row.DeviceLastSeen, true)}
            variant="subtitle2"
          >
            {row.DeviceLastSeen
              ? moment
                .utc(row.DeviceLastSeen)
                .add(timezoneMins, "minutes")
                .format("YYYY-MM-DD HH:mm")
              : row.DeviceLastSeen}
          </TableGreenTypography>
        </TableCell>
        <TableCell align="left">{getButton()}</TableCell>
      </TableRow>
      <AlertDialog
        open={openDialog}
        onClose={handleClose}
        onDoFunction={() => handleAssign(!(row.status === "Assigned"))}
        saveLoading={saveLoading}
        dialogTitle={
          row.status === "Assigned"
            ? t("Are you sure you want to unassign this machine/device?")
            : t("Are you sure you want to assign this machine/device?")
        }
        cancelLabel={t("Cancel")}
        buttonLabel={row.status === "Assigned" ? t("Unassign") : t("Assign")}
      />
    </>
  );
}
DeviceTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  handleChangeData: PropTypes.func,
  labelId: PropTypes.string,
  iKey: PropTypes.number,
};
