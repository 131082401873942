

import React, { Fragment } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import StyledTypography from '../common/StyledTypography';


export default function DeviceNotificationsTableRow({ row, handleClick, isItemSelected, index }) {
    return (
        <Fragment>
            <TableRow
                hover
                onClick={(event) => handleClick(event, index)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={index}
                selected={isItemSelected}
                sx={{ cursor: 'pointer' }}
            >
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.notifcation}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.info}</StyledTypography>
                </TableCell>
                <TableCell align="left">
                    <StyledTypography variant="subtitle2" >
                        {row.timestamp}</StyledTypography>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}
DeviceNotificationsTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    handleClick: PropTypes.func,
    isItemSelected: PropTypes.bool,
    index: PropTypes.number,
};