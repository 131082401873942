import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import { TableRow, TableCell, Button } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import StyledTypography from "../common/StyledTypography";
import AlertDialog from "../common/AlertDialog";
import OrganizationService from "../api/organization";
import { getUrlVars } from "../utils/systemInfo";
import { showMessage } from "../store/messageSlice";

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  minWidth: "90px",
  width: "fit-content",
  height: "36px",
  margin: "0px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));

export default function SubOrganizationTableRow({
  row,
  handleClick,
  isItemSelected,
  handleGetSubOrganizationAgain,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const OrganizationId = String(getUrlVars()["id"]);
  function getButton() {
    const status = row.linkUid;
    if (!status) {
      return (
        <StyledButton
          className="viewUser"
          hidden
          onClick={() => setOpenDialog(true)}
        >
          Assign
        </StyledButton>
      );
    } else {
      return (
        <StyledButton
          className="viewUser"
          hidden
          onClick={() => setOpenDialog(true)}
        >
          Remove
        </StyledButton>
      );
    }
  }
  function handleClose() {
    setOpenDialog(false);
  }
  function handleAssign() {
    const status = row.linkUid;
    const apiKey = localStorage.getItem("apiKey");
    OrganizationService.SetSubOrganizationLink({
      primary_profile_uid: OrganizationId,
      sub_profile: [
        {
          sub_profile_uid: row.uid,
          status: status ? "unlink" : "link",
        },
      ],
      apiKey,
    })
      .then(() => {
        dispatch(
          showMessage({
            message: status
              ? t("suborganization unlinked Successfully")
              : t("suborganization linked Successfully"), //text or html
            autoHideDuration: 10000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "success", //success error info warning null
          })
        );
        handleGetSubOrganizationAgain();
        setSaveLoading(false);
      })
      .catch((errorr) => {
        setSaveLoading(false);
        if (process.env.NODE_ENV === "development")
          console.log("getUserTypePermissionserrorr", errorr);
      });
    setOpenDialog(false);
  }
  return (
    <>
      <Fragment>
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.name}
          selected={isItemSelected}
          sx={{ cursor: "pointer" }}
        >
          <TableCell
            onClick={(event) => handleClick(event, row.fullName)}
            align="left"
          >
            <StyledTypography variant="subtitle2">
              {row.fullName}
            </StyledTypography>
          </TableCell>
          <TableCell
            onClick={(event) => handleClick(event, row.fullName)}
            align="left"
          >
            <StyledTypography variant="subtitle2">
              {row.shortName}
            </StyledTypography>
          </TableCell>
          <TableCell
            onClick={(event) => handleClick(event, row.fullName)}
            align="left"
          >
            <StyledTypography variant="subtitle2">
              {row.status}
            </StyledTypography>
          </TableCell>
          <TableCell align="left">{getButton()}</TableCell>
        </TableRow>
      </Fragment>
      <AlertDialog
        open={openDialog}
        onClose={handleClose}
        onDoFunction={() =>
          handleAssign(!(row.status === "link" || row.status === "active"))
        }
        saveLoading={saveLoading}
        dialogTitle={
          row.linkUid
            ? t("Are you sure you want to remove this sub Organization?")
            : t("Are you sure you want to assign this sub Organization?")
        }
        cancelLabel={t("Cancel")}
        buttonLabel={row.linkUid ? t("Remove") : t("Assign")}
      />
    </>
  );
}
SubOrganizationTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  isItemSelected: PropTypes.bool,
  handleGetSubOrganizationAgain: PropTypes.func,
};
