import React from 'react';
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import PropTypes from 'prop-types';


export default function StyledTypography({
  color,
  children,
  variant,
  sx,
  style,
}) {
  const theme = useTheme();
  return (
    <Typography
      variant={variant ? variant : "subtitle2"}
      sx={{
        ...sx,
        color: color ?? theme.palette.custom.text,
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
      }}
      style={style}
    >
      {children}
    </Typography>
  );
}
StyledTypography.propTypes = {
  color: PropTypes.string,
  children: PropTypes.element,
  variant: PropTypes.string,
  sx: PropTypes.object,
  style: PropTypes.object,
};
