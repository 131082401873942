import React from "react";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from "prop-types";
import StyledTypography from '../common/StyledTypography';
import ViewButton from '../common/viewButton';

export default function ExceptionsTableRow({ handleClick, row, labelId }) {
    const theme = useTheme();
    return (
        <TableRow
            hover
            tabIndex={-1}
            key={row.name}
            sx={{ cursor: 'pointer' }}
        >
            <TableCell
                sx={{ height: '100%', color: theme.palette.custom.text }}
                component="th"
                id={labelId}
                scope="row"
                padding="16"
            >
                <Box sx={{ textAlign: "center", width: '100%', display: 'flex', justifyContent: 'left' }}>
                    <StyledTypography variant="subtitle2" >
                        {row.name}</StyledTypography>  </Box>

            </TableCell>
            <TableCell
                sx={{ height: '100%', color: theme.palette.custom.text }}
                component="th"
                id={labelId}
                scope="row"
                padding="16"
            >
                <Box sx={{ textAlign: "center", width: '100%', display: 'flex', flexFlow: 'row', justifyContent: 'left' }}>
                    <StyledTypography variant="subtitle2" >
                        {row.datetime_start.substring(0, row.datetime_start.length - 7)}</StyledTypography>  </Box>

            </TableCell>
            <TableCell
                sx={{ height: '100%', color: theme.palette.custom.text }}
                component="th"
                id={labelId}
                scope="row"
                padding="16"
            >
                <Box sx={{ textAlign: "center", width: '100%', display: 'flex', flexFlow: 'row', justifyContent: 'left' }}>
                    <StyledTypography variant="subtitle2" >
                        {row.datetime_end.substring(0, row.datetime_end.length - 7)}</StyledTypography>  </Box>

            </TableCell>
            <TableCell
                sx={{ height: '100%', color: theme.palette.custom.text }}
                component="th"
                id={labelId}
                scope="row"
                padding="16"
            >
                <Box sx={{ textAlign: "center", width: '100%', display: 'flex', flexFlow: 'row', justifyContent: 'left' }}>
                    <StyledTypography variant="subtitle2" >
                        {row.description}</StyledTypography>  </Box>

            </TableCell>
            <TableCell
                sx={{ height: '100%', color: theme.palette.custom.text }}
                component="th"
                id={labelId}
                scope="row"
                padding="16"
            >
                <Box sx={{ textAlign: "center", width: '100%', display: 'flex', flexFlow: 'row', justifyContent: 'left' }}>
                    <StyledTypography variant="subtitle2" >
                        {row.status}</StyledTypography>  </Box>

            </TableCell>
            <TableCell align="left">
                <ViewButton onClick={(event) => handleClick(event, row.name, row.scheduled_time_exception_id)} />
            </TableCell>
        </TableRow>
    );
}
ExceptionsTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    handleClick: PropTypes.func,
    labelId: PropTypes.string,
};