import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TextField from "@mui/material/TextField";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Loading from "../common/loading";
import Breadcrumbs from "../common/breadcrumbs";
import TicketsService from "../api/tickets";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TemplatesTableRow from "./templatesTableRow";
import exportTableToCSV from "../common/customFunctions/exportTableToCSV";
import StyledTypography from "../common/StyledTypography";
import { getComparator, stableSort } from "../utils/systemInfo";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Ticket Dashboard", href: "indexHelpdesk" },
  { id: 2, name: "Tickets", href: "tickets" },
];

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    sort: true,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    sort: true,
  },
  {
    id: "department_id",
    numeric: false,
    disablePadding: false,
    label: "Department",
    sort: true,
  },
  {
    id: `created_timestamp`,
    numeric: false,
    disablePadding: false,
    label: "Created",
    sort: true,
  },
  {
    id: `updated_timestamp`,
    numeric: false,
    disablePadding: false,
    label: "Updated",
    sort: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    sort: false,
  },
];

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  height: "36px",
  backgroundColor: theme.palette.custom.buttonColor,
  color: theme.palette.custom.buttonTextColor,
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
  float: "right",
  width: "min-content",
  whiteSpace: "nowrap",
  padding: "1rem",
}));
let templateBackendList = [];
function Templates() {
  const theme = useTheme();
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const [loadingValue, setLoadingValue] = useState(true);
  const [templatesList, setTemplatesList] = useState(undefined);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("updated_timestamp");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      setTemplatesList(null);
      templateBackendList = [];
      TicketsService.GetTicketTemplateHeaders("", "", "active", apiKey)
        .then((json) => {
          if (
            json &&
            json.ticket_template_header &&
            json.ticket_template_header.length > 0
          ) {
            // setTemplatesList(json.ticket_template_header.filter((item) => item.status === "active"))

            setTemplatesList(json.ticket_template_header);
            templateBackendList = json.ticket_template_header;
          }
          setLoadingValue(false);
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    } else {
      setTemplatesList(null);
      setLoadingValue(false);
    }
  }, []);

  const handleEditButton = () => {
    //  navigate(`/deviceProfile?device=${DeviceId}&DeviceTitleDataName=${DeviceTitleDataName}`)
  };
  const handleBreadCrumbsArray = (breadcrumbs) => {
    if (breadcrumbs.length === 3) {
      return breadcrumbs;
    }
    // breadcrumbs.push({ id: 1, name: device.DeviceName, href: `/device?device=${device.DeviceID}` })
    return breadcrumbs;
  };
  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    if (
      newSearchText &&
      newSearchText.length > 0 &&
      templatesList &&
      templatesList.length > 0
    ) {
      setTemplatesList(
        templateBackendList.filter((item) =>
          item.name.toLowerCase().includes(newSearchText.toLowerCase())
        )
      );
    }
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (templatesList && templatesList.length > 0) {
        return stableSort(templatesList, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, templatesList]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: { xs: "fit-content", sm: "64px" },
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            {" "}
            {/* <HomePagesMenu pageName={'Maintenance'} /> */}
            {!loadingValue ? (
              <Breadcrumbs breadcrumbs={handleBreadCrumbsArray(breadcrumbs)} />
            ) : (
              <Box sx={{ height: "30vh", pt: "10%" }}>
                <Loading />
              </Box>
            )}
            <Typography
              sx={{
                fontSize: "45px",
                mb: 0,
                color: theme.palette.custom.text,
              }}
              variant="h2"
              gutterBottom
            >
              {/* {capitalize(t('Devices'))} */}
              {t("Templates")}
            </Typography>
          </Box>
          <StyledButton
            variant="contained"
            onClick={handleEditButton}
            startIcon={<EditIcon />}
          >
            {t("NewTemplate")}
          </StyledButton>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <TextField
        value={searchText}
        onChange={handleSearch}
        margin="normal"
        fullWidth
        id="search"
        label={t("Search")}
        name="search"
        autoFocus
        sx={{
          width: { xs: "100%", sm: "20%" },
          // borderColor: theme.palette.custom.borderColor,
          //borderRadius: '11px',
          backgroundColor: theme.palette.custom.light,
        }}
      />
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              hideCheck={true}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {templatesList &&
                templatesList.length > 0 &&
                visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TemplatesTableRow
                      key={index}
                      row={row}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={templatesList ? templatesList.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.custom.buttonColor,
          color: theme.palette.custom.buttonTextColor,
          "&:hover": {
            backgroundColor: theme.palette.custom.buttonHoverColor,
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
          float: "right",
          width: "min-content",
          whiteSpace: "nowrap",
          padding: "1rem",
        }}
        onClick={() =>
          exportTableToCSV(headCells, visibleRows, "Templates List")
        }
      >
        {t("Download List (*.csv)")}
      </Button>
    </Box>
  );
}

export default Templates;
