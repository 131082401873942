import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Loading from "../common/loading";
import Breadcrumbs from "../common/breadcrumbs";
import RequirementComponent from "./requirementComponent";
import { getUrlVars } from "../utils/systemInfo";
import TicketsService from "../api/tickets";
import CustomTextField from "../common/CustomTextField";


const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Ticket Dashboard", href: "indexHelpdesk" },
  { id: 2, name: "Tickets", href: "tickets" },
];

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  width: "50px",
  height: "32px",
  margin: "0px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));
const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.custom.borderColor,
    width: "10%",
    "&.MuiLoadingButton-loading": {
      backgroundColor: theme.palette.custom.light,
    },
  })
);
function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}
function TicketTemplateRequirements() {
  const theme = useTheme();
  const { t } = useTranslation();
  const [loadingValue, setLoadingValue] = useState(false);
  const organziation = useSelector(({ organziation }) => organziation);
  const [openDialog, setOpenDialog] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [currentGroup, setCurrentGroup] = useState(undefined);
  const [groupIndex, setGroupIndex] = useState(undefined);
  const [showIndex, setShowIndex] = useState(false);
  const [requirementArray, setRequirementsArray] = useState([]);
  const [savingLoading, setSavingLoading] = useState(false);
  const templateId = String(getUrlVars()["templateId"]);
  useEffect(() => {
    if (organziation) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      TicketsService.GetTemplateInputs(templateId, apiKey)
        .then((json) => {
          if (json?.ticket_template) {
            setRequirementsArray(json.ticket_template[0].requirements);
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
        });
      setLoadingValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organziation]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleAddRequirementButton = () => {
    setOpenDialog(true);
    setGroupName(undefined);
    setGroupDescription(undefined);
    setGroupIndex(requirementArray ? requirementArray.length + 1 : 1);
  };
  const handleBreadCrumbsArray = (breadcrumbs) => {
    if (breadcrumbs.length === 3) {
      return breadcrumbs;
    }
    // breadcrumbs.push({ id: 1, name: device.DeviceName, href: `/device?device=${device.DeviceID}` })
    return breadcrumbs;
  };
  function handleChangeNewGroupDialog(value, group) {
    switch (group) {
      case "name":
        setGroupName(value);
        break;
      case "description":
        setGroupDescription(value);
        break;
      case "order_by":
        setGroupIndex(value);
        break;
      default:
        break;
    }
  }

  function handleCreateNewGroup() {
    let requirementslist = [];
    setShowIndex(false);
    requirementslist = requirementArray ? [...requirementArray] : [];
    const apiKey = organziation?.api_key_list?.[0]?.key;
    TicketsService.SetTicketTemplateRequirementHeaders({
      template_requirement_uid: currentGroup
        ? currentGroup.template_requirement_uid
        : "",
      template_header_uid: "" + templateId + "",
      name: groupName,
      description: groupDescription,
      order_by: groupIndex
        ? groupIndex
        : requirementslist.length === 0
          ? 1
          : requirementslist.length + 1,
      status: "active",
      apiKey,
    })
      .then((json) => {
        if (json?.ticket_template_requirement?.length) {
          const requirementobj = json.ticket_template_requirement[0];
          if (currentGroup) {
            const requirementindex = requirementslist.findIndex(
              (x) =>
                x.template_requirement_uid ===
                currentGroup.template_requirement_uid
            );
            requirementslist[requirementindex].name = groupName;
            requirementslist[requirementindex].description = groupDescription;
            requirementslist[requirementindex].order_by = groupIndex;
          } else {
            requirementslist.push({
              name: groupName,
              description: groupDescription,
              inputs: currentGroup ? currentGroup.inputs : [],
              template_header_uid: templateId,
              template_requirement_uid: requirementobj.template_requirement_uid,
              order_by:
                requirementslist.length === 0 ? 1 : requirementslist.length + 1,
            });
          }

          // setRequirementsArray(undefined);
          setRequirementsArray(requirementslist);
          setGroupName(undefined);
          setGroupDescription(undefined);
          setGroupIndex(undefined);
          setCurrentGroup(undefined);
          setOpenDialog(false);
        }
      })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
    requirementslist.push({
      name: groupName,
      description: groupDescription,
      inputs: [],
      template_header_uid: templateId,
      order_by: requirementslist.length === 0 ? 1 : requirementslist.length + 1,
    });
    // setRequirementsArray(undefined);
    setRequirementsArray(requirementslist);
    setGroupName(undefined);
    setGroupDescription(undefined);
    setOpenDialog(false);
  }
  function handleDeleteGroup(index) {
    let requirementslist = [...requirementArray];
    let inputObject = requirementslist[index];
    // setRequirementsArray(undefined);
    const apiKey = organziation?.api_key_list?.[0]?.key;

    TicketsService.SetTicketTemplateRequirementHeaders({
      template_requirement_uid: inputObject.template_requirement_uid,
      template_header_uid: "" + templateId + "",
      name: inputObject.name,
      description: inputObject.description,
      order_by: inputObject.order_by,
      status: "removed",
      apiKey,
    })
      // .then((json) => { })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
    // setRequirementsArray(undefined);
    requirementslist.splice(index, 1);
    setRequirementsArray(requirementslist);
  }
  function handleEditGroup(index) {
    let requirementslist = [...requirementArray];
    const requirementObject = requirementslist[index];
    setCurrentGroup(requirementObject);
    setGroupDescription(requirementObject.description);
    setGroupIndex(requirementObject.order_by);
    setGroupName(requirementObject.name);
    setOpenDialog(true);
    setShowIndex(true);
  }
  function handleChangeInput(groupIndex, index, value, name) {
    let requirementList = [...requirementArray];

    // setRequirementsArray(undefined);
    requirementList[groupIndex].inputs[index][name] = value;
    if (
      name === "input_requirement_config_uid" &&
      (value === 5 || value === 6)
    ) {
      requirementList[groupIndex].inputs[index].select = [];
      requirementList[groupIndex].inputs[index].select.push({
        description: "",
        name: "",
        order_by:
          requirementList[groupIndex].inputs[index].select.length === 0
            ? 1
            : requirementList[groupIndex].inputs[index].select + 1,
        status: "active",
        template_requirement_input_select_uid: "",
        template_requirement_input_uid:
          requirementList[groupIndex].inputs[index]
            .template_requirement_input_uid,
      });
    }
    setRequirementsArray(requirementList);
  }
  const handleAddInput = (groupIndex) => {
    let requirementList = [...requirementArray];
    let inputslist = [...requirementList[groupIndex].inputs];

    inputslist.push({
      name: "",
      input_requirement_config_uid: "",
      description: "",
      order_by: inputslist.length === 0 ? 1 : inputslist.length + 1,
      status: "active",
      template_header_uid: templateId,
    });
    requirementList[groupIndex].inputs = inputslist;
    setRequirementsArray(requirementList);
  };
  const handleSaveInput = (groupIndex, index) => {
    const input = requirementArray[groupIndex].inputs[index];
    const apiKey = organziation?.api_key_list?.[0]?.key;
    TicketsService.SetTemplateInput({
      template_requirement_input_uid: input.template_requirement_input_uid,
      template_requirement_uid: input.template_requirement_uid,
      input_requirement_config_uid: input.input_requirement_config_uid,
      name: input.name,
      description: input.description,
      order_by: input.order_by,
      status: "active",
      apiKey,
    })
      // .then((json) => { })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
  };
  function handleDeleteInputFromGroup(groupIndex, index) {
    let requirementList = [...requirementArray];
    let inputslist = [...requirementList[groupIndex].inputs];
    let inputObject = inputslist[index];
    // setRequirementsArray(undefined);
    inputslist.splice(index, 1);
    const apiKey = organziation?.api_key_list?.[0]?.key;
    TicketsService.SetTemplateInput({
      template_requirement_input_uid:
        inputObject.template_requirement_input_uid,
      template_requirement_uid: inputObject.template_requirement_uid,
      input_requirement_config_uid: inputObject.input_requirement_config_uid,
      name: inputObject.name,
      description: inputObject.description,
      order_by: inputObject.order_by,
      status: "removed",
      apiKey,
    })
      // .then((json) => { })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
    requirementList[groupIndex].inputs = inputslist;
    setRequirementsArray(requirementList);
  }
  function handleDeleteInputOption(groupIndex, index, optionIndex) {
    let requirementList = [...requirementArray];
    let inputslist = [...requirementList[groupIndex].inputs];
    let optionslist = [...inputslist[index].select];
    let optionbject = optionslist[optionIndex];
    const apiKey = organziation?.api_key_list?.[0]?.key;
    optionslist.splice(index, 1);
    TicketsService.SaveInputChooseItems({
      template_requirement_input_select_uid:
        optionbject.template_requirement_input_select_uid,
      input_requirement_config_uid: optionbject.input_requirement_config_uid,
      template_requirement_input_uid:
        optionbject.template_requirement_input_uid,
      description: optionbject.description,
      name: optionbject.name,
      order_by: optionbject.order_by,
      status: "removed",
      apiKey,
    })
      // .then((json) => { })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
    inputslist[index].select = optionslist;
    requirementList[groupIndex].inputs = inputslist;
    setRequirementsArray(requirementList);
  }
  function handleAddInputOption(groupIndex, index) {
    let requirementList = [...requirementArray];
    requirementList[groupIndex].inputs[index].select.push({
      description: "",
      name: "",
      order_by:
        requirementList[groupIndex].inputs[index].select.length === 0
          ? 1
          : requirementList[groupIndex].inputs[index].select + 1,
      status: "active",
      template_requirement_input_select_uid: "",
      template_requirement_input_uid:
        requirementList[groupIndex].inputs[index]
          .template_requirement_input_uid,
    });
    setRequirementsArray(requirementList);
  }

  function handleChangeInputOption(groupIndex, index, value, optionIndex) {
    let requirementList = [...requirementArray];

    requirementList[groupIndex].inputs[index].select[optionIndex].name = value;

    setRequirementsArray(requirementList);
  }
  function handleSaveInputOption(groupIndex, index, value, optionIndex) {
    const option =
      requirementArray[groupIndex].inputs[index].select[optionIndex];
    const apiKey = organziation?.api_key_list?.[0]?.key;
    TicketsService.SaveInputChooseItems({
      template_requirement_input_select_uid:
        option.template_requirement_input_select_uid,
      input_requirement_config_uid: option.input_requirement_config_uid,
      template_requirement_input_uid: option.template_requirement_input_uid,
      description: option.description,
      name: option.name,
      order_by: option.order_by,
      status: "active",
      apiKey,
    })
      // .then((json) => { })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
  }
  function handleSubmit(event) {
    setSavingLoading(true);
    event.preventDefault();
    if (requirementArray) {
      //  requirementArray.forEach(requirementObject => {
      //   TicketsService
      //   .SetDeviceProfile({
      //       "device_profile_id": deviceProfileId,
      //       "device_uid": deviceId,
      //       "serial": deviceSerial,
      //       "name": deviceName,
      //       "oem": deviceOem,
      //   })
      //   .then((json) => {
      //       navigate(`/device?device=${DeviceId}&DeviceTitleDataName=${DeviceTitleDataName}`)
      //   })
      //   .catch((errorr) => {
      //       if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr)
      //   });
      //  });
    }
    setSavingLoading(false);
  }
  function handleMoveGroupDown(moveIndex) {
    let requirementList = [...requirementArray];
    const returnarray = array_move(requirementList, moveIndex, moveIndex + 1);
    returnarray[moveIndex].order_by = returnarray[moveIndex].order_by - 1;

    returnarray[moveIndex + 1].order_by =
      Number(returnarray[moveIndex + 1].order_by ?? 0) + 1;

    const movedObject = returnarray[moveIndex + 1];
    const secondMovedObject = returnarray[moveIndex];
    const apiKey = organziation?.api_key_list?.[0]?.key;

    TicketsService.SetTicketTemplateRequirementHeaders({
      template_requirement_uid: movedObject.template_requirement_uid,
      template_header_uid: movedObject.template_header_uid,
      name: movedObject.name,
      description: movedObject.description,
      order_by: movedObject.order_by,
      status: movedObject.status,
      apiKey,
    })
      // .then((json) => { })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
    TicketsService.SetTicketTemplateRequirementHeaders({
      template_requirement_uid: secondMovedObject.template_requirement_uid,
      template_header_uid: secondMovedObject.template_header_uid,
      name: secondMovedObject.name,
      description: secondMovedObject.description,
      order_by: secondMovedObject.order_by,
      status: secondMovedObject.status,
      apiKey,
    })
      // .then((json) => { })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
    setRequirementsArray(returnarray);
  }
  function handleMoveGroupUp(moveIndex) {
    let requirementList = [...requirementArray];
    const returnarray = array_move(requirementList, moveIndex, moveIndex - 1);
    returnarray[moveIndex - 1].order_by =
      returnarray[moveIndex - 1].order_by - 1;
    returnarray[moveIndex].order_by = returnarray[moveIndex].order_by + 1;
    const movedObject = returnarray[moveIndex];
    const secondMovedObject = returnarray[moveIndex - 1];
    const apiKey = organziation?.api_key_list?.[0]?.key;

    TicketsService.SetTicketTemplateRequirementHeaders({
      template_requirement_uid: movedObject.template_requirement_uid,
      template_header_uid: movedObject.template_header_uid,
      name: movedObject.name,
      description: movedObject.description,
      order_by: movedObject.order_by,
      status: movedObject.status,
      apiKey,
    })
      // .then((json) => { })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
    TicketsService.SetTicketTemplateRequirementHeaders({
      template_requirement_uid: secondMovedObject.template_requirement_uid,
      template_header_uid: secondMovedObject.template_header_uid,
      name: secondMovedObject.name,
      description: secondMovedObject.description,
      order_by: secondMovedObject.order_by,
      status: secondMovedObject.status,
      apiKey,
    })
      // .then((json) => { })
      .catch((errorr) => {
        if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
      });
    setRequirementsArray(returnarray);
  }
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          {" "}
          {/* <HomePagesMenu pageName={'Maintenance'} /> */}
          {!loadingValue ? (
            <Breadcrumbs breadcrumbs={handleBreadCrumbsArray(breadcrumbs)} />
          ) : (
            <Box sx={{ height: "30vh", pt: "10%" }}>
              <Loading />
            </Box>
          )}
          <Typography
            sx={{
              fontSize: "45px",
              mb: 0,
              color: theme.palette.custom.borderColor,
            }}
            variant="h2"
            gutterBottom
          >
            {/* {capitalize(t('Devices'))} */}
            {t("Templates")}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          {" "}
          {/* <HomePagesMenu pageName={'Maintenance'} /> */}
          <Typography sx={{ mb: 0, color: "black" }} variant="h6" gutterBottom>
            {/* {capitalize(t('Devices'))} */}
            {t("Requirements")}
            {":"}
          </Typography>
        </Box>
        <StyledButton
          variant="contained"
          onClick={handleAddRequirementButton}
          startIcon={<EditIcon />}
          sx={{ width: "270px" }}
        >
          {t("NewRequirementGroup")}
        </StyledButton>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      {requirementArray &&
        requirementArray.map((requirement, index) => (
          <RequirementComponent
            key={requirement?.name}
            requirement={requirement}
            groupIndex={index}
            handleTriggerDeleteGroup={handleDeleteGroup}
            handleChangeInput={handleChangeInput}
            handleAddInput={handleAddInput}
            handleDeleteGroupInput={handleDeleteInputFromGroup}
            handleSaveInput={handleSaveInput}
            handleTriggerEditGroup={handleEditGroup}
            handleAddInputOption={handleAddInputOption}
            handleChangeInputOption={handleChangeInputOption}
            handleSaveInputOption={handleSaveInputOption}
            handleDeleteInputOption={handleDeleteInputOption}
            handleMoveGroupDown={handleMoveGroupDown}
            handleMoveGroupUp={handleMoveGroupUp}
            requirementsCount={requirementArray.length}
          />
        ))}
      <Box sx={{ width: "100%", alignItems: "end", textAlign: "end" }}>
        <StyledLoadingButton
          loading={savingLoading}
          loadingIndicator={<CircularProgress color="primary" size={16} />}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 1 }}
          onClick={handleSubmit}
        >
          {t("Save")}
        </StyledLoadingButton>
      </Box>
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : null}
      <Dialog
        onClose={handleCloseDialog}
        open={openDialog}
        sx={{
          width: "100%",
          // backgroundColor: theme.palette.custom.backgroundColor,
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: theme.palette.custom.backgroundColor,
            color: theme.palette.custom.buttonTextColor,
          }}
        >
          {t("AddRequirementGroup")}
        </DialogTitle>
        <DialogContent
          sx={{ backgroundColor: theme.palette.custom.backgroundColor }}
        >
          <CustomTextField
            id="txtUser"
            label={t("Name")}
            variant="filled"
            value={groupName}
            onChange={(event) =>
              handleChangeNewGroupDialog(event.target.value, "name")
            }
          />
          <CustomTextField
            id="txtUser"
            label={t("Description")}
            variant="filled"
            value={groupDescription}
            onChange={(event) =>
              handleChangeNewGroupDialog(event.target.value, "description")
            }
          />
          {showIndex && (
            <CustomTextField
              id="txtUser"
              label={t("Index")}
              variant="filled"
              value={groupIndex}
              onChange={(event) =>
                handleChangeNewGroupDialog(event.target.value, "order_by")
              }
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{ backgroundColor: theme.palette.custom.backgroundColor }}
        >
          <StyledButton variant="contained" onClick={handleCreateNewGroup}>
            {t("Save")}
          </StyledButton>
          <StyledButton variant="contained" onClick={handleCloseDialog}>
            {t("Cancel")}
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default TicketTemplateRequirements;
