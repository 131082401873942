/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setSubOrganizationsData = createAsyncThunk(
  "subOrganizations/setSubOrganizationsData",
  async (inputData) => {
    let RecordOPSUM = [];
    if (inputData === null) {
      return null;
    }
    RecordOPSUM = PopulateDataHomeOPSUM(inputData.jsonCurrent);
    if (
      inputData.newSearchText &&
      inputData.newSearchText.length > 0 &&
      RecordOPSUM &&
      RecordOPSUM.length > 0
    ) {
      const searchText = inputData.newSearchText.toLowerCase();
      return RecordOPSUM.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText)
        )
      );
    }
    return RecordOPSUM;
  }
);
function PopulateDataHomeOPSUM(json) {
  let resultArray = [];
  if (json.linked && json.linked.length) {
    json.linked.forEach((element) => {
      let orgDataObj = {
        shortName: element.short_name,
        fullName: element.full_name,
        uid: element.uid,
        image: "",
        status: element.status,
        linkUid: element.link?.link_uid,
      };
      if (orgDataObj.fullName !== "") resultArray.push(orgDataObj);
    });
  }
  if (json.available && json.available.length) {
    json.available.forEach((element) => {
      let orgDataObj = {
        shortName: element.short_name,
        fullName: element.full_name,
        uid: element.uid,
        image: "",
        status: element.status,
        linkUid: null,
      };
      if (orgDataObj.fullName !== "") resultArray.push(orgDataObj);
    });
  }
  return resultArray;
}

const initialState = null;

const subOrganizationsSlice = createSlice({
  name: "subOrganizations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setSubOrganizationsData.fulfilled,
      (state, action) => action.payload
    );
  },
});

export const selectSubOrganizations = ({ subOrganizations }) =>
  subOrganizations;

export default subOrganizationsSlice.reducer;
