import React, { useState, Fragment } from "react";
import { Box, TableRow, TableCell, Collapse, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { t } from "i18next";
import StyledTypography from "../common/StyledTypography";
import EditButton from "../common/editButton";
import handleNavigate from "../common/customFunctions/handleNavigate";
import CollapseButton from "../common/collapseButton";

export default function ServerHubConfigTableRow({
  row,
  handleClick,
  isItemSelected,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  function handleOpenCollapse() {
    setOpen(!open);
  }
  function handleClickEdit() {
    // function handleClickEdit(event) {
    // if (row && row.signal_descriptor_id) {
    //   const href = `/editDeviceSignal?signalDescriptorId=${row.signal_descriptor_id}`;
    //   handleNavigate(event, navigate, href);
    //   // navigate(href);
    // }
  }
  return (
    <Fragment>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.name}
        selected={isItemSelected}
        sx={{ cursor: "pointer" }}
      >
        <TableCell>
          <CollapseButton handleOpenCollapse={handleOpenCollapse} open={open} />
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">{row.name}</StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">
            {row.description}
          </StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">
            {row.serverId}
          </StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">{row.status}</StyledTypography>
        </TableCell>
        <TableCell align="left">
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <EditButton onClick={handleClickEdit} />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow key={row.name}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <StyledTypography sx={{ fontWeight: "bold" }} variant="subtitle2">
                {`${t("ATTRIBUTES FOR")}:${row.name}`}
              </StyledTypography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: { xs: "block", sm: "center" },
                minHeight: "64px",
                flexWrap: { xs: "wrap", sm: "nowrap" },
              }}
            >
              <StyledTypography variant="subtitle2">
                {`${t("ServerID")}:`}&nbsp;{`${row.serverId}`}
              </StyledTypography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                  gap: "1rem",
                }}
              >
                <StyledTypography variant="subtitle2">
                  {`${t("Status")}:`}&nbsp;{`${row.status}`}
                </StyledTypography>
                <Button
                  variant="contained"
                  sx={{
                    height: "36px",
                    backgroundColor: theme.palette.custom.buttonColor,
                    color: theme.palette.custom.buttonTextColor,
                    "&:hover": {
                      backgroundColor: theme.palette.custom.buttonHoverColor,
                      color: theme.palette.custom.buttonHoverTextColor,
                      boxShadow: "none",
                    },
                    float: "right",
                    width: "min-content",
                    whiteSpace: "nowrap",
                    padding: "1rem",
                  }}
                  onClick={(event) => {
                    handleNavigate(event, navigate, "/organizationCreate");
                    // navigate();
                  }}
                >
                  {t("EditAttribute")}
                </Button>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
ServerHubConfigTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  isItemSelected: PropTypes.bool,
};
